import React,{useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import { LocationModal } from "./LocationModal";
const AccessDeny=({NoAccess,setNoAccess,setLocationAddress})=>{
    const [Access, setAccess] = useState(false);
    const [LocationpopupType, setLocationpopupType] = useState("")
    const handleClose = () => {
      setAccess(false)
      setNoAccess("")
    };
    const HandlePopupModal=()=>{
      setAccess(false)
      setLocationpopupType("success")
    }
    const GetData = () => {
        if (NoAccess === "success") {
          setAccess(true)
        }
        else {
          setAccess(false)
        }
    }
    useEffect(()=>{
        GetData(); 
    },[NoAccess])
    return(
        <>
        <div>
         <Modal
        show={Access}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <p onClick={HandlePopupModal} className="modal-back">Back</p>
        <div className="non-serviceable-step">
         <img src={require("../../Pages/images/AccessImage.png")} className="ns--image" alt="AccessImage"/>
         <div className="ns-exclamation">
         Oh No!
         </div>
         <div>
         We are currently not serving in your area. We are actively working on expanding our reach and will be available in your area in the near future.
         </div>
        </div>
        </Modal.Body>
      </Modal>
      {
        LocationpopupType==="success"&&
        <LocationModal LocationpopupType={LocationpopupType} setLocationpopupType={setLocationpopupType} setLocationAddress={setLocationAddress}/>
      }
     
      </div>
        </>
    )
}
export {AccessDeny}
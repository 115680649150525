import React from 'react';
import { Footer, Navbar } from '../Components';
import CustomTop from './CustomTop';

const TermConditions = () => {
    return (
        <>
            <CustomTop />
            <div className='main-container'>
                <Navbar />
                <div className="container privacy-policy">
                    <h1 style={{ textAlign: 'center' }}>Term & Conditions</h1>
                    <p><strong>Effective Date:</strong> 10th August 2024</p>
                    <h4>1. Introduction</h4>
                    <p>
                        Welcome to Hyderabad Casting Club World, operated by MHN Informatics
                        Private Limited ("we," "us," "our"). These Terms and Conditions govern
                        your use of our website <a href="https://www.hyderabadcastingclub.com" target="_blank" rel="noopener noreferrer">www.hyderabadcastingclub.com</a> and its associated
                        services. By accessing or using our platform, you agree to comply with
                        and be bound by these terms.
                    </p>
                    <h4>2. Use of the Platform</h4>
                    <p><strong>2.1. Eligibility</strong></p>
                    <p>
                        You must be at least 18 years old to use our platform. By using our
                        services, you represent and warrant that you are of legal age and have
                        the capacity to enter into a binding agreement.
                    </p>
                    <p><strong>2.2. Account Registration</strong></p>
                    <p>
                        To access certain features, you may need to create an account. You agree
                        to provide accurate, complete, and up-to-date information during
                        registration and to update such information to keep it accurate,
                        complete, and current.
                    </p>
                    <p><strong>2.3. User Responsibilities</strong></p>
                    <p>
                        You are responsible for maintaining the confidentiality of your account
                        credentials and for all activities that occur under your account. You
                        agree to use our platform only for lawful purposes and in accordance
                        with these Terms and Conditions.
                    </p>
                    <h4>3. Pro/Premium Subscriptions</h4>
                    <p><strong>3.1. Subscription Plans</strong></p>
                    <p>
                        We offer Pro/Premium subscriptions with additional features and
                        benefits. Details of the subscription plans and pricing are available on
                        our website.
                    </p>
                    <p><strong>3.2. Payment</strong></p>
                    <p>
                        Subscription fees are billed annually. Payment information is processed
                        securely through third-party payment processors.
                    </p>
                    <p><strong>3.3. Cancellation and Refunds</strong></p>
                    <p>
                        You may cancel your Pro/Premium subscription at any time. Refunds are
                        subject to our Refund Policy outlined here.
                    </p>
                    <h4>4. User Content</h4>
                    <p><strong>4.1. Content Ownership</strong></p>
                    <p>
                        You retain ownership of the content you upload, including images,
                        videos, and bios ("User Content"). By uploading User Content, you grant
                        us a non-exclusive, royalty-free, worldwide license to use, reproduce,
                        modify, and display such content in connection with our platform.
                    </p>
                    <p><strong>4.2. Content Guidelines</strong></p>
                    <p>
                        You agree not to upload content that is unlawful, defamatory, obscene,
                        or infringes on the rights of others. We reserve the right to remove any
                        content that violates these guidelines or is otherwise objectionable.
                    </p>
                    <h4>5. Intellectual Property</h4>
                    <p><strong>5.1. Platform Rights</strong></p>
                    <p>
                        All intellectual property rights in our platform, including but not
                        limited to software, trademarks, and logos, are owned by MHN Informatics
                        Private Limited. You may not use, reproduce, or distribute any of our
                        intellectual property without our prior written consent.
                    </p>
                    <p><strong>5.2. User License</strong></p>
                    <p>
                        We grant you a limited, non-exclusive, non-transferable license to
                        access and use our platform for personal and non-commercial purposes in
                        accordance with these Terms and Conditions.
                    </p>
                    <h4>6. Limitation of Liability</h4>
                    <p>
                        Our platform is provided "as is" and "as available." We make no
                        warranties, express or implied, regarding the reliability, accuracy, or
                        availability of the platform. To the fullest extent permitted by law,
                        MHN Informatics Private Limited will not be liable for any indirect,
                        incidental, special, or consequential damages arising out of or in
                        connection with your use of our platform.
                    </p>
                    <h4>7. Indemnification</h4>
                    <p>
                        You agree to indemnify and hold harmless MHN Informatics Private Limited
                        and its affiliates, officers, directors, employees, and agents from and
                        against any claims, liabilities, damages, losses, or expenses arising
                        out of or in connection with your use of our platform or any violation
                        of these Terms and Conditions.
                    </p>
                    <h4>8. Changes to Terms</h4>
                    <p>
                        We reserve the right to modify these Terms and Conditions at any time.
                        Any changes will be posted on our website, and your continued use of the
                        platform constitutes acceptance of the updated terms.
                    </p>
                    <h4>9. Governing Law</h4>
                    <p>
                        These Terms and Conditions are governed by and construed in accordance
                        with the laws of [Your Jurisdiction], without regard to its conflict of
                        law principles.
                    </p>
                    <h4>10. Contact Us</h4>
                    <p>
                        If you have any questions or concerns about these Terms and Conditions,
                        please contact us at:
                    </p>
                    <p>
                        <strong>Email:</strong> info@hyderabadcastingclub.com
                        <br />
                        <strong>Address:</strong> H.NO-8-2-1/b/1, Sri Nagar Colony, Hyderabad, Telangana – 500082
                    </p>
                </div >
            </div>
            <Footer />
        </>

    );
};

export { TermConditions };

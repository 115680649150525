import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import axiosConfig from "../../Service/axiosConfig"
import { useSelector, useDispatch } from "react-redux";
import { addFollower, removeFollower } from "../../redux/Slices/cartSlice";
import { FaUserCheck } from "react-icons/fa";
import { LiaUserCheckSolid } from "react-icons/lia";


const Follow = (props) => {
    let { FollowId, setIsDeleted, updateLikesCount } = props;
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    const [ShortId, setShortId] = useState(false);
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const addToFollowers = (id) => {
        if (localStorage.getItem('token')) {
            let profile_data = JSON.parse(localStorage.getItem('profile_data'))
            axiosConfig.post(`/accounts/follows/`, { user: profile_data.id, profile: id })
                .then(response => {
                    dispatch(addFollower({ FollowId: id, Follow_id: response.data.id }));
                    console.log("Product added to followers:", response.data);
                    console.log("follow id:", ShortId, cart.followers, FollowId)
                    updateLikesCount("like")
                })
                .catch(error => {
                    console.error("Error adding product to followers:", error);
                })
        } else {
            handleLoginShow()
        }
    };
    const removeToFollowers = () => {
        let followers = JSON.parse(localStorage.getItem('followers'))
        let FollowItem = followers.find((p) => p.id === FollowId);
        console.log(FollowItem.Follow_id)
        if (FollowItem.Follow_id) {
            axiosConfig.delete(`/accounts/follows/${FollowItem.Follow_id}/`)
                .then(response => {
                    dispatch(removeFollower({ 'FollowId': FollowId }));
                    updateLikesCount("dislike")
                    setIsDeleted(true)
                    console.log("remove product from followers", response)

                })
                .catch(error => {
                    console.error("Error removing product from followers:", error);
                })
        }

    };

    return (
        <>
            {cart.followers.some((p) => p.id === FollowId) ? (
                <button className="btn btn-short shortlist-icon" onClick={() => removeToFollowers()}>Following</button>
                // <p onClick={() => removeToFollowers()} style={{ margin: 0 }}>Following</p>
            ) : (
                <button className="btn btn-shortlist shortlist-icon" onClick={() => addToFollowers(FollowId)}>Follow</button>
                // <p style={{ margin: 0 }} onClick={() => addToFollowers(FollowId)}>Follow</p>
            )}
        </>
    )
}
export { Follow }
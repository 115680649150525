import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { addProduct, selectCartProducts, selectCartTotal, removeProduct } from "../../redux/Slices/cartSlice";
import { MdAdd, MdRemove } from "react-icons/md";
import axiosConfig from "../../Service/axiosConfig"
const ViewCart = ({handleClose,setOrders,Order}) => {
  const products = useSelector(selectCartProducts);
  const total = useSelector(selectCartTotal);
  const dispatch = useDispatch();
  const [productAmount, setProductAmount] = useState({
    mrp: 0,
    discount: 0,
    delviryCharges: 0,
    PackingCharges: 0,
    totalAmount: 0,
    proamount:0 
   })
  let ProductData=[]
  //  const productTotal = total;
  //  console.log(productTotal)
  //  localStorage.setItem('total', productTotal);
  const handleRemove = (productId, quantity) => {
    dispatch(removeProduct({ productId, quantity }));
  };

  const addToCart = (product) => {
    dispatch(addProduct({ product: product, quantity: 1 }));
    console.log("added")
  };
  const ProductMRP = async(data) => {
    let sum = data && data.reduce(function (prev, current) {
      const updatedItem = { ...current };
      const offerPrice = parseFloat(current.offer_price);
      const price = parseFloat(current.price);
      if (offerPrice === 0) {
        updatedItem.price = (price + offerPrice)
        return prev + +updatedItem.price * updatedItem.quantity
      } else if (offerPrice > 0) {
        updatedItem.price = offerPrice
        return prev + +updatedItem.price * updatedItem.quantity
      }
      return prev
    }, 0);
    setProductAmount({
      mrp:total,
    discount:sum-total,
    delviryCharges:0,
    PackingCharges:0,
    totalAmount:sum,
    proamount:sum
    }) 
    try{
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async(position)=>{
            console.log(position.coords.altitude)
             const Responce= await axiosConfig.get(`/masters/get_delivery/?delivery_type=Instant&order_value=${sum}&store_id=${localStorage.getItem("storeId")}&user_latitude=${position.coords.latitude}&user_longitude=${position.coords.longitude}`)
             let Amount=sum>=499?0:Responce.data.delivery_charges
             setProductAmount({
               mrp:total,
             discount:sum-total,
             delviryCharges:Responce.data.delivery_charges,
             PackingCharges:0,
             totalAmount:sum+Amount,
             proamount:sum
             }) 
             console.log(Responce,Amount)
            const totalWeight = data.reduce(
              (prevValue, currentValue) => prevValue + currentValue.weight_in_kg*currentValue.quantity,
              0
            );
            const packingcharges= await axiosConfig.get(`/masters/get_packings/?order_wt=${parseFloat(totalWeight.toFixed(0))}`)
            console.log(packingcharges.data.packing_charges,sum)
            setProductAmount({
              mrp:total,
            discount:sum-total,
            delviryCharges:Responce.data.delivery_charges,
            PackingCharges:packingcharges.data.packing_charges,
            totalAmount:sum+Amount+packingcharges.data.packing_charges,
            proamount:sum,
            }) 
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }
     catch(error){
     console.log(error.response.data.error="Delivery charges not found for the given parameters")
  }
}
  useEffect(() => {
    ProductMRP(products)
    products.forEach(res => {
      ProductData.push({
        "id":res.id,
        "min_qty":res.quantity,
    })
  })
  setOrders&&setOrders({...Order,"products":ProductData})
  const serializedState = JSON.stringify(products);
  products.length>0&&localStorage.setItem('state', serializedState)
  }, [products])
  return (
    <div>
      {products.length === 0 ? (
        <div className="Empty-cart">
        <div> 
            <img className="empty-img" src={require("../Navbar/images/emp_empty_cart.png")} alt="emp"/>
        </div>
        <p className="p-delivery-time">You don't have any items in your cart</p>
        <p className="message-cart">Your favourite items are just a click away</p>
        <button type="button" className="btn--link" onClick={() => handleClose()}>Start Shoping</button>
        {/* <p className="p-qty">{cart.products.length} items</p> */}
    </div>
      ) : (
        <>
          <div className="cartProducts">
            {products.map((product) => (
              <div className="productItem" key={product.id}>
                <div className="row">
                  <div className="col-2">
                    <img src={product.image} className="cart-display-img" />
                  </div>
                  <div className="col-6">
                    <p>{product.name}</p>
                    {product.weight_in_kg && product.weight_in_kg > 0 ? (
                      <>
                        <p>{product.weight_in_kg}</p>
                      </>
                    ):(<></>)}
                    <div className="d-flex">
                      {product.offer_price && product.offer_price > 0 ? (
                        <>
                          <p className="cart-item-price">₹{product.offer_price}</p>
                          <p className={product.offer_price ? "produc-price ms-2" : "cart-item-price"}>₹{product.price}</p>
                        </>
                      ) : (
                        <p className="cart-item-price">₹{product.price}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="p-btn">
                      <div className="btn btn-cart btn-cart-added">
                        <button className="btn-minus" onClick={() => handleRemove(product.id, 1)}>
                          <MdRemove />
                        </button>
                        <div className="qty">{product.quantity}</div>
                        <button className="btn-minus" disabled={product.max_qty === product.quantity} onClick={() => addToCart(product)}>
                          <MdAdd />
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <td>{product.name}</td>
                  <td>${product.price.toFixed(2)}</td>
                  <td>{product.quantity}</td>
                  <td>${(product.price * product.quantity).toFixed(2)}</td>
                  <td>
                    <button onClick={() => handleRemove(product.id, product.quantity)}>Remove</button>
                  </td> */}
              </div>
            ))}
          </div>
          <div className="bill-details">
            <div className="bill-head">
              Bill Details
            </div>
            <div className="bill-status">
              <div className="mrp-head">
                MRP
              </div>
              <div>
                ₹{productAmount.mrp}
              </div>
            </div>
            <div className="bill-status">
              <div className="mrp-head">
                Product discount
              </div>
              <div>
                {productAmount.discount}
              </div>
            </div>
            <div className="bill-status">
              <div className="mrp-head">
                Delivery charge
              </div>
              {productAmount.proamount>=499?<div>
               <strike>₹{productAmount.delviryCharges}</strike> <green>Favour</green> 
              </div>:
              <div>
                  ₹{productAmount.delviryCharges}
              </div>
              }
            </div>
            <div className="bill-status">
              <div className="mrp-head">
                Packing Charges
              </div>
              <div>
                ₹{productAmount.PackingCharges}
              </div>
            </div>
            <div className="grand-total">
              <div className="total-price">
                Grand total
              </div>
              <div className="grand-total">
                ₹{productAmount.totalAmount}
              </div>
            </div>
          </div>
          <div className="cuppons-aply">
            Coupons are only applicable on the Opoto app
          </div>
          <div className="CancellationPolicy">
            Cancellation Policy
          </div>
          <div className="CancellationPolicy-content">
            Orders cannot be cancelled once packed for delivery. In case of unexpected delays, a refund will be provided, if applicable.
          </div>
        </>
      )}
    </div>
  );
}
export { ViewCart };

import React from 'react';
import { Footer, Navbar } from '../Components';
import CustomTop from './CustomTop';

const PrivacyPolicy = () => {
    return (
        <>
            <CustomTop />
            <div className='main-container'>
                <Navbar />
                {/* <div className="container privacy-policy">
                    <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>
                    <p><strong>About Your Privacy:</strong></p>
                    <p>
                        By collecting personal information from our website, we provide you with an understanding of this privacy policy.
                        When you use our website, you agree to accept our privacy policy objectives.
                    </p>
                    <p><strong>Data Collection and Use:</strong></p>
                    <p>
                        When you browse our website, we collect the information you provide. We only use the information you provide to us for
                        collection purposes and may use it with your permission. We only use the information you provide and may not share your
                        information with anyone else.
                    </p>
                    <p><strong>Accepted Methods:</strong></p>
                    <p>
                        Destroying your collected personal information, using our website only as intended, or for marketing purposes, or using it
                        for other purposes, we may revoke your permission.
                    </p>
                    <p><strong>Security:</strong></p>
                    <p>
                        To keep your information safe, we employ tools designed for this purpose.
                    </p>
                    <p><strong>Permissions:</strong></p>
                    <p>
                        You agree to our privacy policy and accept the terms and conditions if you continue to use our website.
                    </p>
                    <p>
                        Remember, this is a basic template. It's crucial to adapt it to your specific website and consult legal professionals to
                        ensure compliance with relevant laws and regulations.
                    </p>
                </div> */}
                <div className="container privacy-policy">
                    <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>
                    <p><strong>Effective Date:</strong> 10th August 2024</p>
                    <h3>1. Introduction</h3>
                    <p>
                        Welcome to Hyderabad Casting Club World, operated by MHN Informatics
                        Private Limited ("we," "us," "our"). We are committed to protecting your
                        privacy and ensuring that your personal information is handled in a safe
                        and responsible manner. This Privacy Policy outlines how we collect,
                        use, disclose, and safeguard your information when you visit our website{" "}
                        <a href="https://www.hyderabadcastingclub.com" target="_blank" rel="noopener noreferrer">
                            www.hyderabadcastingclub.com
                        </a>{" "}
                        and use our services.
                    </p>

                    <h3>2. Information We Collect</h3>
                    <p>We collect various types of information to provide and improve our services:</p>
                    <ul>
                        <li><strong>Personal Information:</strong></li>
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Address</li>
                            <li>Payment information (for Pro/Premium subscriptions)</li>
                        </ul>
                        <li><strong>Profile Information:</strong></li>
                        <ul>
                            <li>Profile photo</li>
                            <li>Bio</li>
                            <li>Skills and experience</li>
                            <li>Portfolio images and videos</li>
                        </ul>
                        <li><strong>Usage Data:</strong></li>
                        <ul>
                            <li>Details of your visits to our website</li>
                            <li>The resources you access</li>
                            <li>The time and date of your visit</li>
                            <li>The pages you visit</li>
                            <li>Your IP address, browser type, and operating system</li>
                        </ul>
                    </ul>

                    <h3>3. How We Collect Information</h3>
                    <p>We collect information through various methods:</p>
                    <ul>
                        <li><strong>Directly from you:</strong> When you register on our platform, create or update your profile, apply for auditions, or subscribe to Pro/Premium services.</li>
                        <li><strong>Automatically:</strong> Through cookies and similar tracking technologies as you navigate through our website.</li>
                        <li><strong>Third Parties:</strong> From service providers such as payment processors, and from public databases.</li>
                    </ul>

                    <h3>4. How We Use Your Information</h3>
                    <p>We use the information we collect for various purposes, including:</p>
                    <ul>
                        <li>To provide and maintain our services</li>
                        <li>To process your registration and manage your account</li>
                        <li>To facilitate communication and collaboration between users</li>
                        <li>To process payments for subscriptions</li>
                        <li>To send you updates, promotional materials, and other information related to our services</li>
                        <li>To improve our website and services</li>
                        <li>To ensure security and prevent fraud</li>
                    </ul>

                    <h3>5. How We Share Your Information</h3>
                    <p>We may share your information with third parties in the following situations:</p>
                    <ul>
                        <li><strong>With service providers:</strong> To perform services on our behalf, such as payment processing and data analysis.</li>
                        <li><strong>With other users:</strong> When you apply for auditions or interact with other users on our platform.</li>
                        <li><strong>For legal reasons:</strong> If required by law or in response to valid requests by public authorities.</li>
                    </ul>

                    <h3>6. Data Security</h3>
                    <p>We take appropriate measures to ensure the security of your personal information. These measures include:</p>
                    <ul>
                        <li>Using SSL encryption to protect data transmitted to and from our website</li>
                        <li>Regularly updating our software and infrastructure</li>
                        <li>Restricting access to personal information to authorized personnel only</li>
                    </ul>

                    <h3>7. Your Rights</h3>
                    <p>You have the following rights regarding your personal information:</p>
                    <ul>
                        <li><strong>Access:</strong> You can request a copy of the personal information we hold about you.</li>
                        <li><strong>Correction:</strong> You can request that we correct any inaccurate or incomplete information.</li>
                        <li><strong>Deletion:</strong> You can request that we delete your personal information.</li>
                        <li><strong>Objection:</strong> You can object to our processing of your personal information.</li>
                        <li><strong>Restriction:</strong> You can request that we restrict the processing of your personal information.</li>
                        <li><strong>Data Portability:</strong> You can request a copy of your personal information in a structured, commonly used, and machine-readable format.</li>
                    </ul>

                    <h3>8. Changes to This Privacy Policy</h3>
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of
                        any changes by posting the new Privacy Policy on our website. You are
                        advised to review this Privacy Policy periodically for any changes.
                    </p>

                    <h3>9. Contact Us</h3>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at:
                    </p>
                    <p>
                        <strong>Email:</strong> info@hyderabadcastingclub.com
                        <br />
                        <strong>Address:</strong> H.NO-8-2-1/b/1, Sri Nagar Colony, Hyderabad, Telangana – 500082
                    </p>

                    <p>
                        By using our website and services, you acknowledge that you have read and
                        understood this Privacy Policy and agree to our collection, use, and
                        sharing of your personal information as described herein.
                    </p>
                </div>
            </div>
            <Footer />
        </>

    );
};

export { PrivacyPolicy };

import React, { useState, useEffect } from "react";
import "./Navbar.css"
import axiosConfig from "../../Service/axiosConfig"
import { useSelector } from "react-redux";
import { selectCartTotal } from "../../redux/Slices/cartSlice";
import { MdOutlineSearch, MdOutlineExplore, MdChatBubbleOutline, MdOutlineNotifications, MdOutlineAddToPhotos, MdOutlineMovieCreation, MdOutlineAccountCircle } from 'react-icons/md';

import { MdShoppingCart, MdOutlineHome, MdSearch, MdOutlineAddCircleOutline, MdWorkOutline, MdOtherHouses } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { ViewCart } from "./ViewCart"
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { Login, Signup } from "../Login";
import { AddressModal, LocationModalNavBar } from "../PopupModal";
import { IoMdArrowDropdown } from "react-icons/io"
import { AccessDeny, LocationModal } from "../PopupModal";
import { error } from "jquery";
import { RiAccountCircleLine } from "react-icons/ri"
import { useAuth } from '../../Utils/AuthContext';
import { Tabs, Tab } from "react-bootstrap";

const Topbar = ({ search }) => {
    const location = useLocation();

    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();

    const Navigate = useNavigate();
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const { cart } = useSelector((state) => state);
    const total = useSelector(selectCartTotal);
    const [show, setShow] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const [query, setQuery] = useState(search);
    const [count, setCount] = useState(1)
    const handleClose = () => setShow(false);
    const handleLoginClose = () => setloginShow(false);
    const handleShow = () => setShow(true);
    const handleLoginShow = () => setloginShow(true);
    const [popupType, setPopupType] = useState("")
    const [Address, setAddress] = useState([])
    const [orderAddress, setOrderAddress] = useState()
    const [ActiveUrl, setActiveUrl] = useState("")
    const [ProfileData, setProfileData] = useState({})
    const [LocationNavBarType, setLocatioNavBarType] = useState("")
    const [NoAccess, setNoAccess] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [key, setKey] = useState('Login');

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    let ProductData = []
    const [Order, setOrders] = useState({
        "address": "",
        "products": [],
        "stores": localStorage.getItem("stores"),
        "user_id": localStorage.getItem("userId")
    })
    console.log(Order, cart)
    function handleInputChange(event) {
        setQuery(event.target.value);
    }
    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }
    const GetlocaData = () => {
        const Accounturl = localStorage.getItem("activeurl")
        setActiveUrl(Accounturl)
    }
    function handleSubmit(event) {
        event.preventDefault();
        Navigate(`/product-search?query=${query}`)
    }

    function handleLoginEvent() {
        handleLoginClose(false);
        setIsLoggedIn(true);
        setProfile()
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true)
            setProfile()
        }
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const GetData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/address/?user=${localStorage.getItem("userId")}`)
            setAddress(response.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const GetTheAddress = () => {
        setCount(2)
        GetData()
    }
    const GetAddress = async (id) => {
        try {
            const response = await axiosConfig.get(`/accounts/address/${id}/`)
            setOrderAddress(response.data)
            localStorage.setItem("name", response.data.name)
            localStorage.setItem("address_id", response.data.id)
            cart.products.forEach(res => {
                console.log(res)
                ProductData.push({
                    "id": res.id,
                    "min_qty": res.quantity,
                })
            })
            setOrders(
                {
                    "stores": parseFloat(localStorage.getItem("storeId")), "user_id": parseFloat(localStorage.getItem("userId")), "address": id,
                    "products": ProductData,
                }
            )

        }
        catch (error) {
            console.log(error)
        }

    }
    const ChangeAddress = () => {
        setCount(2)
        setOrderAddress()
    }
    const newAddressAdded = () => {
        GetData();
    }
    const MovetoCart = () => {
        setCount(1)
        setOrderAddress()
    }
    const HandleAddress = () => {
        setPopupType("success")
    }

    const PayMentNavigate = async () => {
        let order_obj = Order
        if (localStorage.getItem("orderId")) {
            order_obj['order_id'] = localStorage.getItem("orderId")
        }
        await axiosConfig.post(`/accounts/orders/`, Order).then(res => {
            localStorage.setItem("orderId", res.data.id)
            Navigate("/checkout")
        }).catch(error => {
            console.log(error)
        })
    }
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    useEffect(() => {
        if (location.pathname === '/signup') {
            if (localStorage.getItem("token")) {
                Navigate("/")
            } else {
                setloginShow(true)
                setKey("Signup")
                setloginView("signup")
            }
        }
        GetData();
        setProfile();
        CheckLogin();
        GetlocaData();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location.pathname])
    const MobileLogin = () => {
        Navigate("/account")
    }
    useEffect(() => {
        if (key === 'Login') {
            setloginView('login');
        } else if (key === 'Signup') {
            setloginView('signup');
        }
    }, [key]);
    const hcc_id = localStorage.getItem('hcc_id');
    return (
        <>

            {isMobile ? <div className="navBar-mobile-main">
                <header className="nav-mobile-header">
                    <div className="nav-mobile-location">
                        <div>

                            <div className="nav-mobile-store">
                                {localStorage.getItem("address")}
                            </div>
                        </div>
                        <div onClick={isLoggedIn ? MobileLogin : handleLoginShow}>
                            <RiAccountCircleLine className="account-mobile" />
                        </div>
                    </div>
                    <div className="search-mobile-main">
                        <NavLink to="/s" className="position-relative">
                            <div className="form-control">
                                <MdSearch className="search-icon" />
                                <input type="text" value={query} onChange={handleInputChange} />
                            </div>
                        </NavLink>
                    </div>
                </header>
            </div> :
                <div className="navbar-container">
                    <nav className="navbar fixed-top navbar-expand-lg">
                        <div className="container-fluid">
                            <NavLink to="/" className="navbar-brand" >
                                <img src={require("./images/hclogo.png")} alt="logo" />
                            </NavLink>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <div className="location-nav-main">

                                </div>
                                <form className="m-auto search-form" >

                                </form>
                                <ul className="navbar-nav ml-auto">
                                    {isLoggedIn && is24Craft &&
                                        <li class="nav-item">
                                            <NavLink to="/new-post">
                                                <MdOutlineAddToPhotos className="top-new-post" />
                                            </NavLink>
                                        </li>
                                    }
                                    {isLoggedIn &&
                                        <>
                                            <li class="nav-item">
                                                <NavLink to="/">
                                                    <MdChatBubbleOutline className="top-new-post" />
                                                </NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to="/">
                                                    <MdOutlineNotifications className="top-new-post" />
                                                </NavLink>
                                            </li>
                                        </>
                                    }
                                    {isLoggedIn ? (
                                        <li className="nav-item dropdown account-topnav">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={ProfileData && ProfileData.profile_pic ? ProfileData.profile_pic : require("../Post/images/male.jpg")} width="40" className="profile-pic-top" alt={`${ProfileData?.first_name} ${ProfileData?.last_name}`} />
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <a className="dropdown-item">
                                                        <NavLink to={`/profile/${hcc_id}`} >My Account</NavLink>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item">
                                                        <NavLink to={`/payment-history`} >Payment History</NavLink>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item">
                                                        <NavLink to={`/profile/${hcc_id}`} >Change Password</NavLink>
                                                    </a>
                                                </li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><NavLink to="/logout" className="dropdown-item" onClick={() => ActiveData("/logout")}>Log Out</NavLink></li>
                                            </ul>
                                        </li>
                                    ) : (
                                        <li className="nav-item"><a className="nav-link" href="#" onClick={handleLoginShow}>Login</a></li>
                                    )}


                                </ul>

                            </div>
                        </div>
                    </nav>
                    <div className="navbar-bottom"></div>
                </div>}





            {count === 1 && <Modal show={show} onHide={handleClose} className="popup-cart">
                <Modal.Header closeButton>
                    <Modal.Title>My Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body className={orderAddress ? "cart-modal-rn" : "cart-modal-address"}>
                    <div>
                        <p className="p-delivery-time">Delivery in 20 minutes</p>
                        <p className="p-qty">{cart.products.length} items</p>
                        <ViewCart handleClose={handleClose} ProductData={ProductData} Order={Order} setOrders={setOrders} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="delivery-location">
                        {orderAddress && <div className="row">
                            <div className="col-2 nav-locat-icon">
                                {orderAddress.adress_type === "Home" && <MdOutlineHome /> || orderAddress.adress_type === "Work" && <MdWorkOutline /> || orderAddress.adress_type === "Others" && <MdOtherHouses />}

                            </div>
                            <div className="col-10">
                                <p className="add-type">Delivering to {orderAddress.adress_type}</p>
                                <div className="row">
                                    <div className="col-10">
                                        <p className="del-address">{orderAddress.name},{orderAddress.flat_no},{orderAddress.address},{orderAddress.landmark},{orderAddress.pincode}</p>
                                    </div>
                                    <div className="col-2 change-nav" onClick={ChangeAddress}>
                                        <p>Change</p>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                    <button className="btn btn-success btn-proceed" onClick={localStorage.getItem("userId") ? orderAddress === undefined ? GetTheAddress : PayMentNavigate : handleLoginShow}>
                        <p className="p-qty">{cart.products.length > 0 ? (
                            <>{cart.products.length} items</>
                        ) : (
                            <p>My Cart</p>
                        )} <span className="p-price">₹{parseFloat(total).toFixed(2)}</span></p>
                        <p className="p-pay">Proceed To Pay</p>
                    </button>
                </Modal.Footer>
            </Modal>}
            {count === 2 && <Modal show={show} onHide={handleClose} className="popup-cart">
                <Modal.Header>
                    <Modal.Title>
                        <div className="cart-address">
                            <div className="left-arrrow" onClick={MovetoCart}>
                                🡠
                            </div>
                            <div className="left-address">
                                My Address
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="cart-modal-rn-address">
                    <div className="add-address-main">
                        <div className="add-address-nav" onClick={HandleAddress}>
                            <div className="add-icon-nav">
                                <MdOutlineAddCircleOutline />
                            </div>
                            <div className="address-head-nav">
                                Add a new address in
                            </div>
                        </div>
                        <div>
                            <div className="address-p">
                                Choose Delivery Address
                            </div>
                        </div>
                        <div>
                            {
                                Address.map((address, i) => {
                                    return (
                                        <div key={i} className="address-main d-flex align-items-center">
                                            <div class="form-check" onClick={() => GetAddress(address.id)}>
                                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                                            </div>
                                            <div>
                                                <div className="address-ass">
                                                    {address.adress_type}
                                                </div>
                                                <div className="address-detail">
                                                    {address.address},{address.landmark},{address.pincode}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success btn-proceed" disabled={orderAddress ? false : true} onClick={() => setCount(1)}>
                        <p className="p-pay">Done</p>
                    </button>
                </Modal.Footer>
            </Modal>}
            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
                    <div className="login-text-type">{loginView === "login" ? "Welcome back !" : "Create Your Account"}</div>
                </Modal.Header>
                <Modal.Body>
                    {loginView == "login" ? (
                        <>
                            <Login handleLoginEvent={handleLoginEvent} />
                            <div className="switchtag" onClick={() => setloginView("signup")} ><span>New user?</span> Sign Up</div>
                        </>
                    ) : (
                        <>
                            <Signup handleLoginEvent={handleLoginEvent} />
                            <div className="switchtag" onClick={() => setloginView("login")} ><span>Already have an account?</span> Click to Login</div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {
                popupType === "success" &&
                <AddressModal popupType={popupType} newAddressAdded={newAddressAdded} setPopupType={setPopupType} />
            }

        </>
    )
}
export { Topbar };
import React, { useEffect, useState } from 'react';
import axiosConfig from "../Service/axiosConfig";
import CustomTop from './CustomTop';
import { Navbar } from '../Components';

const PaymentHistory = ({ ProfileData, can_edit_profile }) => {
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    const GetData = async () => {
        setLoading(true);
        try {
            console.log(profile_data)
            const response = await axiosConfig.get(`/accounts/orders/?profile=${profile_data && profile_data?.profile_id}`);
            setData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        GetData();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <CustomTop />
            <div className="search-screen-container payments-screen">
                <Navbar />
                <div className="content-area">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('./images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <div className="container">
                            <h4>Payment History</h4>
                            {Data && Data.length > 0 ? (
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Plan Name</th>
                                            <th>Price</th>
                                            {/* <th>Duration (months)</th> */}
                                            {/* <th>Total Amount</th> */}
                                            <th>Discount</th>
                                            <th>Net Amount</th>
                                            <th>Payment Date</th>
                                            <th>Order Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.map((history, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{history.plan.name}</td>
                                                <td>{history.plan.price}</td>
                                                {/* <td>{history.plan.duration_months}</td> */}
                                                {/* <td>${history.total_amount}</td> */}
                                                <td>{history.discount_amount}</td>
                                                <td>{history.net_amount}</td>
                                                <td>{formatDate(history.payment_date)}</td>
                                                <td>{history.orderstatus}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No payment history found.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export { PaymentHistory };

import React from 'react';
import { Link } from 'react-router-dom';
import "./index.css"
const BreadCrumbs = ({active_page,prev_sub_page,sub_page}) => {
    return (
        <>
            <section className="breadcrumb-section text-center">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        {prev_sub_page &&
                            <li className="breadcrumb-item"><Link to={prev_sub_page.link}>{prev_sub_page.text}</Link></li>
                        }
                        {sub_page &&
                            <li className="breadcrumb-item"><Link to={sub_page.link}>{sub_page.text}</Link></li>
                        }
                        <li className="breadcrumb-item active" aria-current="page">{active_page}</li>
                    </ol>
                </nav>
            </section>
        </>
    );
};

export  {BreadCrumbs};
import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { DashboardLayout, Navbar, Categories, ProductsList, SubCategories, Footer } from "../Components";
import { SubCategoriesMobile } from "../Components/SubCategories";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { BreadCrumbs } from "../Components/BreadCrums";
import { MobileNavBar } from "../Components/Navbar";
import "./Pages.css"
import { MdSearch } from "react-icons/md";
import { HiArrowNarrowLeft } from "react-icons/hi"
const SubCategoriesPage = (props) => {
    const { category_id, subcategory_id, friendlyurl } = props;
    const [CategorySlug, SetCategorySlug] = useState("")
    const [loading, setLoading] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [Data, setData] = useState([])
    let Navigate = useNavigate()
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    const HandleNavigate = () => {
        Navigate("/")
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/catlog/categories/${category_id}/`)
            SetCategorySlug(response.data.slug)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const Getcategories = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get("/catlog/categories/")
            setData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData();
        Getcategories()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [category_id])

  const HandleFunction=(para)=>{
    console.log(para)
  }
    return (
        <>
            {isMobile ? <div className="navBar-mobile-main">
                <header className="nav-mobile-header">
                    <div className="nav-mobile-location1">
                        <div onClick={HandleNavigate}>
                            <HiArrowNarrowLeft className="leftarroworder" />
                        </div>
                        <div>
                            <div className="nav-mobile-time">
                                Delivery in {localStorage.getItem("traveltime") ? localStorage.getItem("traveltime") : 10} minutes
                            </div>
                            <div className="nav-mobile-store">
                                {localStorage.getItem("address")}
                            </div>
                        </div>
                    </div>
                    <div className="search-mobile-main">
                        <NavLink to="/s" className="position-relative">
                            <div className="form-control">
                                <MdSearch className="search-icon" />
                                <input type="text" />
                            </div>
                        </NavLink>
                    </div>
                    <div className="row sort-main">
                        <form action="/action_page.php">
                            <div className="top-info-container-mobile">
                                <p className="subcategory-title">Buy {friendlyurl} Online</p>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <label for="sortby" className="form-label">Sort By:</label>
                                </div>
                                <div className="col-9">
                                    <select className="form-select" id="sortby" name="sellist1">
                                        <option>Relevance</option>
                                        <option>Price (Low to High)</option>
                                        <option>Price (High to Low)</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </header>
            </div> : <><Navbar />
                <section className="categarios-sub">
                    <div className="cat-sub-main py-2">
                        <div className="container d-flex">
                            {
                                Data.map((item, i) => {
                                    if (i < 6) {
                                        return (
                                            <div key={i}>
                                                <ul>
                                                    <NavLink to={item.slug} className="text-decoration-none"><li className="list">{item.name}</li></NavLink>
                                                </ul>
                                            </div>

                                        )
                                    }

                                })
                            }
                            {/* <select className="mx-4 select-sub">
                                <option value="">More</option>
                                {
                                    Data.map((optionItem, i) => {
                                        if(i>7){
                                            return (
                                                <option onClick={()=>HandleFunction(optionItem.slug)}><NavLink to={optionItem.slug} className="text-decoration-none">{optionItem.name}</NavLink></option>
                                            )
                                        }
                                    })
                                }

                            </select> */}
                             <li className="nav-item dropdown list-unstyled ms-5">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                More
                                            </a>
                                            <ul  className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {
                                                Data.map((option,i)=>{
                                                    if(i>6){
                                                        console.log(option)
                                                    return(
                                                        <li key={i} className="list-unstyled"><NavLink to={option.slug} className="dropdown-item">{option.name}</NavLink></li>
                                                    )
                                                    }
                                                })
                                            }
                                             </ul>
                                        </li>
                                       
                        </div>
                    </div>
                </section>
                <div className="breadcrumb-sub text-center">
                <h1>{friendlyurl}</h1>
                    <BreadCrumbs
                        active_page={friendlyurl}
                        prev_sub_page={{
                            'link':CategorySlug,
                            'text':CategorySlug
                        }}
                    />
                    </div>
            </>
            }
            {isMobile ?
                <section>
                    <div className="">
                        <div className="d-flex">
                            <div className="pad-10">
                                <div className={isMobile ? "subcategories-list-mobile" : "subcategories-list"}>
                                    {isMobile ? <SubCategoriesMobile category_id={category_id} category_slug={CategorySlug} /> : <SubCategories category_id={category_id} category_slug={CategorySlug} />}
                                </div>
                            </div>
                            <div className="">
                                <div className="matched-products-list">
                                    <ProductsList subcategory_id={subcategory_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <div className="container">
                    <div className="catflexpage">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="subcategories-list">
                                    <SubCategories category_id={category_id} category_slug={CategorySlug} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="matched-products-list">
                                    <div className="container">
                                        <div className="top-info-container pt-4">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <p className="subcategory-title">{friendlyurl}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <form action="/action_page.php">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label for="sortby" className="form-label">Sort By:</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <select className="form-select" id="sortby" name="sellist1">
                                                                    <option>Relevance</option>
                                                                    <option>Price (Low to High)</option>
                                                                    <option>Price (High to Low)</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ProductsList subcategory_id={subcategory_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </>
    )

}
export { SubCategoriesPage }
import React, { useEffect, useState } from "react";
import { Topbar, Navbar,Categories,AdRight,AdPost,Footer,Stories } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import { FormParser,PopupModal } from "../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPage } from "./PostsPage";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Masters from "../Components/masters.json"

const ViewCraftEdit = () => {
    const {craftname,craftslug} = useParams();
    let MastersModal = Masters[craftslug]
    console.log(MastersModal);
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const [Error, SetError] = useState("")
    const [loading, setLoading] = useState(false)
    const [formData, SetformData] = useState([])
    const [QuestionsData, SetQuestionsData] = useState([])

    const formSubmit = (Data) => {
        console.log(Data)
            SetError([])
            axiosConfig.patch(`/accounts/${craftslug}/${formData.id}/`, Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                let errorData={}
                console.log(errorData)
                SetformErrorData(errorData)
                   if(error.response.data.country_name[0]==="Enter a valid country name."){
                        errorData.country_name="Enter a valid country name."
                       SetError("Enter a valid country name.")
                  }
                  if(error.response.data.country_name[0]==="This field must be unique."){
                    errorData.country_name="Country Already Exists."
                   SetError("Country Already Exists.")

                  }
                  if(error.response.data.country_name[0]==="This field may not be blank."){
                   SetError("Country may not be blank.")

                  }
                setpopupType("error")
            })
    }

    const GetData = async () => {
        setLoading(true)

        try {
            const responce = await axiosConfig.get(`/accounts/${craftslug}/`)
            console.log(responce)
            SetformData(responce.data.results[0])
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }

    }

    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
        <Topbar />
        <div className="main-container">
            <Navbar />
            
                <div className="content-area">
                    <div className="form-render">
                        <h5>Edit the following details to under {craftname} </h5>
                        <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error}/>
                        {popupType != "" &&
                            <PopupModal popupType={popupType} Navigation="/24crafts"/>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}
export {ViewCraftEdit}
import React, { useEffect, useState } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";
import { Follow } from '../Follow';

const LikesPopup = ({ item, smShow, setSmShow }) => {
    console.log(item, "item")
    const [Likes, setLikes] = useState([]);
    const GetLikes = async () => {
        try {
            console.log(item.id)
            const response = await axiosConfig.get(`/accounts/likes/?modelimage=${item.id}&page_size=100`)
            console.log(response)
            setLikes(response.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetLikes();
    }, [])
    return (
        <>
            < Modal
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Likes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Likes.map((like) => {
                        return (
                            <>
                                <div className='like-content'>
                                    <div className='d-flex' style={{ gap: '10px' }}>
                                        <NavLink target="_blank" to={`/profile/${like.user.hcc_id}`}>
                                            <img
                                                src={like.user.profile_pic ? like.user.profile_pic : require('./images/male.jpg')}
                                                alt={`${like.user?.first_name} ${like.user?.last_name}`} className='profile-like'
                                            />
                                        </NavLink>
                                        <div>
                                            <NavLink target="_blank" to={`/profile/${like.user.hcc_id}`} className="like-name">
                                                <span>{like.user ? `${like.user.first_name}` : ''}</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <Follow FollowId={like.user.profile_id} />
                                    {/* <button className='btn btn-primary' style={{ borderRadius: '8px' }} >Follow</button> */}
                                </div>
                            </>
                        )
                    })}
                </Modal.Body>
            </Modal >
        </>
    );
};

export { LikesPopup };

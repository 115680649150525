import React, { useEffect, useState } from "react";
import "./Navbar.css"
import { Footer, Navbar } from "..";
import { TbReport } from "react-icons/tb"
import { MdOutlineEditLocationAlt } from "react-icons/md"
import { RiWallet3Line } from "react-icons/ri"
import { AiOutlineUser } from "react-icons/ai"
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { HiArrowNarrowLeft } from "react-icons/hi"
import CustomTop from "../../Pages/CustomTop";
const AccountResp = () => {
    const Navigate = useNavigate()
    const HandleNavigate = () => {
        Navigate("/")
    }
    return (
        <>
            {/* <div className="navBar-mobile-main">
                <header className="nav-mobile-header">
                    <div className="nav-mobile-location1">
                      <div onClick={HandleNavigate}>
                         <HiArrowNarrowLeft className="leftarroworder"/>
                      </div>
                        <div>
                            <div className="nav-mobile-time">
                                Delivery in {localStorage.getItem("traveltime") ? localStorage.getItem("traveltime") : 10} minutes
                            </div>
                            <div className="nav-mobile-store">
                                {localStorage.getItem("address")}
                            </div>
                        </div>
                    </div>
                    <div className="search-mobile-main">
                    <NavLink to="/s" className="position-relative">
                                    <div className="form-control">
                                        <MdSearch className="search-icon" />
                                        <input type="text"/>
                                    </div>
                                </NavLink>
                    </div>
                </header>
            </div>  */}
            <CustomTop />
            <div className="account-mobile-nav">
                <div className="user-account-number">
                    <div className="user-account-number-text">
                        {localStorage.getItem("number")}
                    </div>
                </div>
                <div className="account-details">
                    <div className="account-padding">
                        <p className="account-info">Your Information</p>
                        <NavLink to="/account/orders" className="text-decoration-none"><div className="order-histoy">
                            <TbReport className="order-icon" />
                            <div className="icon-name">
                                Order History
                            </div>
                        </div></NavLink>
                        <NavLink to="/account/addresses" className="text-decoration-none"><div className="order-histoy">
                            <MdOutlineEditLocationAlt className="order-icon" />
                            <div className="icon-name">
                                Address Book
                            </div>
                        </div></NavLink>
                        <NavLink to="/account/wallet" className="text-decoration-none"><div className="order-histoy">
                            <RiWallet3Line className="order-icon" />
                            <div className="icon-name">
                                Wallet
                            </div>
                        </div></NavLink>
                        <NavLink to="/logout" className="text-decoration-none"><div className="order-histoy">
                            <AiOutlineUser className="order-icon" />
                            <div className="icon-name">
                                Logout
                            </div>
                        </div></NavLink>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export { AccountResp }
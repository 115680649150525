import React, { useState, useEffect } from "react";


const DatePicker = ({readonly,fieldItem,Data,SetData}) => {
  const dateString = Data[fieldItem.fieldName];
const [dynamicyear, dynamicmonth, dynamicday] = dateString ? dateString.split('-').map(Number) : [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()];
  const [day, setDay] = useState(dynamicday ||"");
  const [month, setMonth] = useState(dynamicmonth || "");
  const [year, setYear] = useState(dynamicyear || "");
  const [daysInMonth, setDaysInMonth] = useState([]);
  const monthList = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" }
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);


  const handleDayChange = (e) => setDay(e.target.value);
  const handleMonthChange = (e) => setMonth(e.target.value);
  const handleYearChange = (e) => setYear(e.target.value);
  useEffect(() => {
    if (month && year) {
      const parsedYear = parseInt(year);
      const parsedMonth = parseInt(month);
      const days = new Date(parsedYear ,parsedMonth, 0).getDate(); // month + 1 to get the correct month
      setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
    } else {
      setDaysInMonth([]);
    }
    if(day &&  month && year){
    const formatted = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    console.log(formatted,"formated")
    SetData({...Data,[fieldItem.fieldName]:formatted});
    }
  }, [day,month, year]);
  return (
    <div className="row">
      <div className="col-4">
        <select class="form-select" aria-label="Default select example" value={day} name={fieldItem.fieldName} onChange={handleDayChange} required={fieldItem.required} disabled={readonly}>
          <option value="">Day</option>
          {daysInMonth.map((d) => (
            <option key={d} value={d}>
              {d}
            </option>
          ))}
        </select>
      </div>
      <div className="col-4">
        <select class="form-select" aria-label="Default select example" value={month} name={fieldItem.fieldName} onChange={handleMonthChange} required={fieldItem.required} disabled={readonly}>
          <option value="">Month</option>
          {monthList.map((m) => (
            <option key={m.value} value={m.value}>
              {m.label}
            </option>
          ))}
        </select>
      </div>
      <div className="col-4">
        <select class="form-select" aria-label="Default select example" value={year} name={fieldItem.fieldName} onChange={handleYearChange} required={fieldItem.required} disabled={readonly}>
          <option value="">Year</option>
          {years.map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
export default DatePicker
import React, { useState, useEffect } from "react";
import { Topbar, Navbar,Categories,AdRight,AdPost,Footer,Stories } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPageGrid } from "./PostsPageGrid";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Modal from 'react-bootstrap/Modal';
import CustomTop from "./CustomTop";
import { MdOutlineModeEditOutline,MdDeleteOutline,MdOutlineEdit } from "react-icons/md";
import { PostPop } from "../Components/Post/PostPop"
const SharedPostPage = () => {
    let {uid,hcc_id} = useParams();
    let can_edit_profile = false
    if(!hcc_id){
        hcc_id = localStorage.getItem("hcc_id")
    }
    if(hcc_id == localStorage.getItem("hcc_id")){
        can_edit_profile = true
    }
    const [Data, SetData] = useState([])

    const [EditProfileFor, setEditProfileFor] = useState("profile");
    const [EditProfileShow, setEditProfileShow] = useState(false);
    const handleEditProfileClose = () => setEditProfileShow(false);

    const [loading, setLoading] = useState(false)

    const [ProfileData, SetProfileData] = useState({})

    function handleLoginEvent() {
        setEditProfileShow(false);
        GetData()
    }


    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/model-images/?uid=${uid}`)
            SetData(responce.data.results[0])
            console.log("sharedpostdata")
            console.log(responce.data.results[0])
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
      setActiveTab(index);
    };

    useEffect(() => {
        GetData();
    }, [])

    return (
        <>
        {/* <Topbar /> */}
        <CustomTop />
        <div className="main-container payments-screen">
            <Navbar />
                <div className="content-area">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('./images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <>
                    <div className="shared-post">
                        {Object.keys(Data).length > 0 &&
                            <PostPop item={Data} handleHideEvent="" />
                        }
                    </div>
                    </>
  )}
                </div>
                
            </div>
            <Footer />
        </>
    )

}
export {SharedPostPage}
import React from "react";
import { DashboardLayout, FormParser } from "../Components";
import { NavLink, Outlet } from "react-router-dom";

import "./Pages.css"
const NotFound = () => {

    return (
        <>
            <h1>Page NotFound</h1>
        </>
    )

}
export {NotFound}
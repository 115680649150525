import React from "react";
import { MdSearch} from "react-icons/md";
import {HiArrowNarrowLeft} from "react-icons/hi"
import { NavLink,useNavigate } from "react-router-dom";
const MobileNavBar=({HandleNavigate})=>{
  const Navigate = useNavigate()
    const HandleNavigat=()=>{
      Navigate("/")
    }
    return(
        <>
        <div className="navBar-mobile-main">
                <header className="nav-mobile-header">
                    <div className="nav-mobile-location1">
                      <div onClick={HandleNavigate?()=>HandleNavigate():HandleNavigat}>
                         <HiArrowNarrowLeft className="leftarroworder"/>
                      </div>
                        <div>
                            <div className="nav-mobile-time">
                                Delivery in {localStorage.getItem("traveltime") ? localStorage.getItem("traveltime") : 10} minutes
                            </div>
                            <div className="nav-mobile-store">
                                {localStorage.getItem("address")}
                            </div>
                        </div>
                    </div>
                    <div className="search-mobile-main">
                    <NavLink to="/s" className="position-relative">
                                    <div className="form-control">
                                        <MdSearch className="search-icon" />
                                        <input type="text"/>
                                    </div>
                                </NavLink>
                    </div>
                </header>
            </div> 
        </>
    )
}
export {MobileNavBar}
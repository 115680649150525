import React, { useState, useEffect } from "react";
import "./SubCategories.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";

const SubCategoriesgrid = (props) => {
    const { category_id, category_slug } = props;
    const [loading, setLoading] = useState(false)
    const [Data, SetData] = useState([])

    const GetData = async (category_id) => {

        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/sub-categories/?category=${category_id}`)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData(category_id);
    }, [category_id])

    return (
        <>
                <div className="categories-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('../images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <>
                            {
                                Data.map((itemObj, index) => {
                                    return (
                                        <div className="subcat-item-container" key={index}>
                                            <NavLink to={`/${category_slug}/${itemObj.slug}`} >
                                                <div className="row align-items-center relclass">
                                                    <div className="col-3 cat-product">
                                                        <img src={itemObj.image}/>
                                                    </div>
                                                    <div className="col-9 text-center mx-auto">
                                                        <p className="sub-p">{itemObj.name}</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )}
                </div>
        </>
    )
}
export { SubCategoriesgrid };
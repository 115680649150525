import React, { useState, useEffect } from "react";
import "./Products.css"
import axiosConfig from "../../Service/axiosConfig"
import { json, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { addProduct, removeProduct } from "../../redux/Slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { MdAdd, MdRemove } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { AccessDeny, LocationModal } from "../PopupModal";
const Product = (props) => {
    const { cart } = useSelector((state) => state);
    const [popupType, setPopupType] = useState("")
    const [NoAccess, setNoAccess] = useState("")
    const [LocationpopupType, setLocationpopupType] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    const dispatch = useDispatch();
    let Data = []
    const Handlepopup = () => {
        setLocationpopupType("")
    }

    const addToCart = () => {
        dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price, offer_price: item.offer_price, weight_in_kg: item.weight_in_kg, max_qty: item.max_qty }, quantity: 1 }));
        console.log("added")
    };
    const addToCart1 = () => {
        dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price, offer_price: item.offer_price, weight_in_kg: item.weight_in_kg, max_qty: item.max_qty }, quantity: 1 }));
        console.log("added")
        localStorage.getItem("storeId") ? Handlepopup() : setLocationpopupType("success")
    };
    const removeFromCart = () => {
        dispatch(removeProduct({ productId: item.id, quantity: 1 }));
        console.log("Item removed from your cart")
    };
    const { item } = props;
    const serializedState = JSON.stringify(cart.products);
    localStorage.setItem('state', serializedState);
    const getProductQuantity = (productId) => {
        const product = cart.products.find((p) => p.id === productId)
        return product ? product.quantity : 0;
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])
    return (
        <>
                    <div className="product-item">
                        <NavLink to={`${item.slug}`} >
                            <div className="product-img">
                                <img src={item.image} />
                            </div>
                        </NavLink>
                        <NavLink to={`${item.slug}`} >
                            <p>{item.name}</p>
                        </NavLink>
                        <div className="product-actions">
                            <div className="p-price">
                                {item.offer_price && item.offer_price > 0 ? (
                                    <>
                                        <p className="price">₹{item.offer_price}</p>
                                        <p className={item.offer_price ? "produc-price" : "price"}>₹{item.price}</p>
                                    </>
                                ) :
                                    <p className="price">₹{item.price}</p>}

                            </div>
                            <div className="p-btn">
                                {cart.products.some((p) => p.id === item.id) ? (
                                    <div className="btn btn-cart btn-cart-added">
                                        <button className="btn-minus" onClick={removeFromCart}>
                                            <MdRemove />
                                        </button>

                                        <div className="qty">{getProductQuantity(item.id)}</div>
                                        <button className="btn-minus" disabled={item.max_qty === getProductQuantity(item.id)} onClick={addToCart}>
                                            <MdAdd />
                                        </button>
                                    </div>
                                ) : (
                                    <button className="btn btn-cart" onClick={addToCart1}>Add</button>
                                )}
                            </div>
                            {
                                LocationpopupType === "success" &&
                                <LocationModal LocationpopupType={LocationpopupType} setLocationpopupType={setLocationpopupType} />
                            }
                            {
                                NoAccess === "success" &&
                                <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} />
                            }
                        </div>
                    </div>
        </>
    )
}
export default Product;
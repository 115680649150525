import React, { useState, useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { MobileNavBar } from "../Components/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineEditLocation } from "react-icons/md"
import { AiOutlineReconciliation, AiOutlineUser } from "react-icons/ai"
import { BiRupee } from "react-icons/bi"
import axiosConfig from "../Service/axiosConfig";
import { GrAddCircle } from "react-icons/gr"
import { AiOutlineHome } from "react-icons/ai"
import { AddressModal } from "../Components/PopupModal";
import {MdWorkOutline,MdOtherHouses} from "react-icons/md"
const MyAddress = () => {
  const [loading, setLoading] = useState()
  const [Address, setAddress] = useState([])
  const [ActiveUrl, setActiveUrl] = useState("")
  const [popupType, setPopupType] = useState("")
  const [updateId, setUpdateId] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  let Navigate=useNavigate()
  const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
  };
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl)
  }
  const HandleNavigate=()=>{
    Navigate("/account")
  }
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetData = async () => {
    setLoading(true)
    try {
      const response = await axiosConfig.get(`/accounts/address/?user=${localStorage.getItem("userId")}`)
      setAddress(response.data.results)
      console.log("sasdas")
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const HandleDelete = async (id) => {
    console.log(id)
    try {
      const response = await axiosConfig.delete(`/accounts/address/${id}/`)
      console.log(response)
      GetData()
    }
    catch (error) {
      console.log(error)
    }
  }
  const HandleEdit=(id)=>{
    setUpdateId(id)
    setPopupType("success")
  }
  const HandleGetAddress=()=>{
    GetData()
  }
  useEffect(() => {
    GetData();
    GetlocaData()
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, [])

  return (
    <>
      {
        isMobile?<MobileNavBar HandleNavigate={HandleNavigate}/>:<Navbar/>
        }
        {
         isMobile?
         <div className="container my-profile_mobile">
         <div className="row">
           <div className="col-12 col-10 my-profile__right_rn">
             {
               Address.length > 0 ? <div className="my-addresses">
                 <button type="button" onClick={() => setPopupType("success")} className="btn btn--inverted my-addresses__new-address-btn icon-add"><GrAddCircle className="mx-2" />Add New Address</button>
                 {
                   Address.map((add, i) => {
                     console.log(add)
                     return (
                       <div key={i}>
                         <div className="address-item-container">
                           <div className="address-item">
                             <div className="d-flex align-items-center gap-2">
                               {add.adress_type==="Home"&&<AiOutlineHome />||add.adress_type==="Work"&&<MdWorkOutline />||add.adress_type==="Others"&&<MdOtherHouses />}
                               <div className="address-label">{add.adress_type}</div>
                             </div>
                             <div className="address-item__value">
                             <span className="text--dark-gray">Address-</span>
                             <span className="text--dark-gray">{add.address},</span>
                             <span className="text--dark-gray">{add.landmark},</span>
                             <span className="text--dark-gray">{add.flat_no},</span>
                             <span className="text--dark-gray">{add.pincode}</span>
                           </div>
                           <div className="d-flex justify-content-end gap-4">
                             <p className="Action-address" onClick={()=>HandleEdit(add.id)}>Edit</p>
                             <p className="Action-address" onClick={()=>HandleDelete(add.id)}>Delete</p>
                       </div>
                           </div>
                           </div>
                         </div>
                           )
                       })
                     }
               </div> : <div className="no-address">
                 <img src={require("./images/no-address.png")} alt="no-address" />
                 <div className="no-address__heading">
                   You have no saved addresses
                 </div>
                 <div className="no-address__sub-heading">
                   Tell us where you want your orders delivered
                 </div>
                 <button type="button" className="btn--link" onClick={() => setPopupType("success")}>Add New Address</button>
               </div>
             }
           </div>
         </div>
         {
           popupType === "success" &&
           <AddressModal popupType={popupType} setPopupType={setPopupType} updateId={updateId} HandleGetAddress={HandleGetAddress} setUpdateId={setUpdateId}/>
         }
       </div>:<div className="container my-profile_rn">
        <div className="row">
          <div className="col-lg-2 col-md-2 profile-main">
            <div className="profile-nav__details-block">
              <div class="account-profile__img"></div>
              <div class="account-profile__phone">{localStorage.getItem("number")}</div>
            </div>
            <nav className="profile-nav__list">
              <ul className="list-unstyled">
                <NavLink to="/account/addresses" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/addresses")}><li className="item-text"><span className="mx-2"><MdOutlineEditLocation /></span>My Addresses</li></NavLink>
                <NavLink to="/account/orders" className={ActiveUrl === "/account/orders" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation /></span>My Orders</li></NavLink>
                <NavLink to="/account/wallet" className={ActiveUrl === "/account/wallet" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee /></span>My wallet</li></NavLink>
                <NavLink to="/logout" className={ActiveUrl === "/logout" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser /></span>Logout</li></NavLink>
              </ul>
            </nav>
          </div>
          <div className="col-lg-10 col-md-10 my-profile__right_rn">
            {
              Address.length > 0 ? <div className="my-addresses">
                <button type="button" onClick={() => setPopupType("success")} className="btn btn--inverted my-addresses__new-address-btn icon-add"><GrAddCircle className="mx-2" />Add New Address</button>
                {
                  Address.map((add, i) => {
                    console.log(add)
                    return (
                      <div key={i}>
                        <div className="address-item-container">
                          <div className="address-item">
                            <div className="d-flex align-items-center gap-2">
                              {add.adress_type==="Home"&&<AiOutlineHome />||add.adress_type==="Work"&&<MdWorkOutline />||add.adress_type==="Others"&&<MdOtherHouses />}
                              <div className="address-label">{add.adress_type}</div>
                            </div>
                            <div className="address-item__value">
                            <span className="text--dark-gray">Address-</span>
                            <span className="text--dark-gray">{add.name},</span>
                            <span className="text--dark-gray">{add.address},</span>
                            <span className="text--dark-gray">{add.landmark},</span>
                            <span className="text--dark-gray">{add.flat_no},</span>
                            <span className="text--dark-gray">{add.pincode}</span>
                          </div>
                          <div className="d-flex justify-content-end gap-4">
                            <p className="Action-address" onClick={()=>HandleEdit(add.id)}>Edit</p>
                            <p className="Action-address" onClick={()=>HandleDelete(add.id)}>Delete</p>
                      </div>
                          </div>
                          </div>
                        </div>
                          )
                      })
                    }
              </div> : <div className="no-address">
                <img src={require("./images/no-address.png")} alt="no-address" />
                <div className="no-address__heading">
                  You have no saved addresses
                </div>
                <div className="no-address__sub-heading">
                  Tell us where you want your orders delivered
                </div>
                <button type="button" className="btn--link" onClick={() => setPopupType("success")}>Add New Address</button>
              </div>
            }
          </div>
        </div>
        {
          popupType === "success" &&
          <AddressModal popupType={popupType} setPopupType={setPopupType} updateId={updateId} HandleGetAddress={HandleGetAddress} setUpdateId={setUpdateId}/>
        }
      </div>
        }
    </>
  )
}
export { MyAddress }
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import axiosConfig from "../../Service/axiosConfig"
import { useSelector, useDispatch } from "react-redux";
import { addWishlist, removeWishlist } from "../../redux/Slices/cartSlice";

const Like = (props) => {

    let { productId, setIsDeleted, updateLikesCount } = props;
    // console.log(props)
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    const [WishId, setWishId] = useState(false);
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const addToWishlist = (id) => {
        if (localStorage.getItem('token')) {
            let profile_data = JSON.parse(localStorage.getItem('profile_data'))
            axiosConfig.post(`/accounts/likes/`, { user: profile_data.id, modelimage: id })
                .then(response => {
                    dispatch(addWishlist({ productId: id, wish_id: response.data.id }));
                    console.log("Product added to wishlist:", response.data);
                    console.log("wish id:", WishId, cart.wishlists, productId)
                    updateLikesCount("like")
                })
                .catch(error => {
                    console.error("Error adding product to wishlist:", error);
                })
        } else {
            handleLoginShow()
        }
    };
    const removeFromWishlist = () => {
        let wishlists = JSON.parse(localStorage.getItem('wishlists'))
        let wishlistItem = wishlists.find((p) => p.id === productId);
        console.log(wishlistItem.wish_id)
        if (wishlistItem.wish_id) {
            axiosConfig.delete(`/accounts/likes/${wishlistItem.wish_id}/`)
                .then(response => {
                    dispatch(removeWishlist({ 'productId': productId }));
                    updateLikesCount("dislike")
                    setIsDeleted(true)
                    console.log("remove product from wishlist", response)

                })
                .catch(error => {
                    console.error("Error removing product from wishlist:", error);
                })
        }

    };
    return (
        <>

            {cart.wishlists.some((p) => p.id === productId) ? (
                <AiFillHeart size={32} class="post-symbol-icons" onClick={() => removeFromWishlist()} color="#6A0DAD" />
            ) : (
                <AiOutlineHeart size={32} class="post-symbol-icons" onClick={() => addToWishlist(productId)} />
            )}
        </>
    )
}
export { Like }
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import axiosConfig from "../../Service/axiosConfig"
const AddressModal = ({ popupType, setPopupType,updateId,setUpdateId,HandleGetAddress,newAddressAdded }) => {
    const [lgShow, setLgShow] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [AddressType, setAddressType] = useState("");
    const [Address,setAddress]=useState({
            "user":localStorage.getItem("userId"),
            "adress_type":AddressType,
            "name":"",
            "address": "",
            "flat_no": "",
            "landmark": "",
            "pincode": "",
            "is_default": false
        
    })
    const GetAddressType=(type)=>{
        setAddressType(type) 
        setAddress({
           ...Address,"adress_type":type
        })
    }
    const HandleAddress=(e)=>{
     const{name,value}=e.target
     console.log(name,value)
     setAddress({...Address,[name]:value})
    }
    const GetData = () => {
        if (popupType === "success") {
            setLgShow(true)
        }
        else {
            setLgShow(false)
        }
    }
    const handleSuccessClose = () => {
        setLgShow(false);
        setPopupType("")
        setUpdateId("")
    }
    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: latitude ? latitude : 17.387140,
        lng: longitude ? longitude : 78.491684
    };
    const { isLoaded } = useJsApiLoader({
        id: 'AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ',
        googleMapsApiKey: "AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ"
    })
    const formSubmit = async(e) => {
        e.preventDefault();
        try{
            const Responce= await axiosConfig.post(`/accounts/address/`,Address)
            console.log(Responce)
            setPopupType("")
            newAddressAdded&&newAddressAdded();
            HandleGetAddress&&HandleGetAddress();

            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                  async(position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                  },
                  (error) => {
                    console.log(error.message);
                  }
                );
              } else {
                console.log('Geolocation is not supported by your browser');
              }
         }
         catch(error){
            console.log(error)
         }
        }
        const FormUpdate = async(e) => {
            e.preventDefault();
            try{
                const Responce= await axiosConfig.put(`/accounts/address/${updateId}/`,Address)
                console.log(Responce)
                setPopupType("")
                setUpdateId("")
                HandleGetAddress&&HandleGetAddress()
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(
                      async(position) => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                      },
                      (error) => {
                        console.log(error.message);
                      }
                    );
                  } else {
                    console.log('Geolocation is not supported by your browser');
                  }
             }
             catch(error){
                console.log(error)
             }
            }
       const GetEditdata=async()=>{
            try {
                const response = await axiosConfig.get(`/accounts/address/${updateId}/`)
                console.log(response)
                setAddress(response.data)
                setAddressType(response.data.adress_type)
                
              }
              catch (error) {
                console.log(error)
              }
        }

    useEffect(() => {
        updateId&&GetEditdata()
        GetData();
    }, [popupType])
    return (
        <>
            <Modal
                size="lg"
                show={lgShow}
                onHide={handleSuccessClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            {isLoaded &&
                                <>
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={10}

                                    >
                                        <Marker position={center} />
                                    </GoogleMap>
                                </>}
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="address-title">
                                Enter complete address
                            </div>
                            <div className="addres-subtitle">
                                This allow us to find you easily and give you timely delivery experience
                            </div>
                            <form>
                                <div className="form-main">
                                    {/* <div className="row">
                                        <div className="col-2">
                                            <select className="select-control">
                                                <option>Title</option>
                                                <option>Miss</option>
                                                <option>Mrs</option>
                                                <option>Mr</option>
                                            </select>
                                            <div>
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <input datatype="text" className="form-control select-control" placeholder="Receivers's Name" />
                                        </div>
                                    </div> */}
                                    <div className="my-4">
                                        <input datatype="text" className="form-control select-control" name="name" value={Address.name} onChange={HandleAddress} placeholder="Name" />
                                    </div>
                                    <div className="my-4">
                                        <textarea  className="form-control select-control" name="address" value={Address.address} onChange={HandleAddress} placeholder="Address" rows="2" />
                                    </div>
                                    <div className="my-4">
                                        <input datatype="text" className="form-control select-control" name="flat_no" value={Address.flat_no} onChange={HandleAddress} placeholder="Flat No" />
                                    </div>
                                    <div className="my-4">
                                        <input datatype="text" className="form-control select-control" name="landmark" value={Address.landmark} onChange={HandleAddress} placeholder="Landmark" />
                                    </div>
                                    <div>
                                        <input  className="form-control select-control" name="pincode" value={Address.pincode} onChange={HandleAddress} placeholder="Pincode" />
                                    </div>
                                    <div className="save-address">
                                        Save Address as
                                    </div>
                                    <div className="d-flex gap-2">
                                        <div className={AddressType==="Home"?"address-2":"address-1"} onClick={()=>GetAddressType("Home")}>
                                            Home
                                        </div>
                                        <div className={AddressType==="Work"?"address-2":"address-1"} onClick={()=>GetAddressType("Work")}>
                                            Work
                                        </div>
                                        <div className={AddressType==="Others"?"address-2":"address-1"} onClick={()=>GetAddressType("Others")}>
                                            Others
                                        </div>
                                    </div>
                                </div>
                                {updateId?<button type="button" className="btn-address w-100" onClick={FormUpdate}>Update Address</button>:<button type="button" className="btn-address w-100" onClick={formSubmit}>Save Address</button>}
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export { AddressModal }
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Plans from './Plans'
import { IsProComponent } from './IsProComponent'
import axiosConfig from "../Service/axiosConfig";
import checkProRealtime from '../Utils/checkProRealtime';


const UpgradePage = ({ UpgradeShow, setUpgradeShow, ScreenVisbile, setScreenVisbile, handleUpgradeClose, RowId1 }) => {
    const [BuyNowData, setbuyNowData] = useState({})
    const [plans, setPlans] = useState({});
    const hnadlescreen = (Title, month, month_3, year, discount) => {
        setbuyNowData({ Title: Title, month_3: month_3, year: year, discount: discount, month: month })
        setScreenVisbile(Title)
    }
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const fetchPlans = async () => {
        try {
            const response = await axiosConfig.get('/masters/plans/');
            console.log(response.data.results[0])
            setPlans(response.data?.results[0]);
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
    };
    const AudiInterest = () => {
        console.log(RowId1)
        axiosConfig.post("/accounts/audition-interests/", { audition_requirement: RowId1 }).then(res => {
            console.log(res);
        }).catch(error => {
            console.log(error);
        });
    }

    const RefreshLogin = async () => {
        try {
            if (localStorage.getItem('is_pro') != "true") {
                const response = await axiosConfig(`/accounts/api/refresh_login/`)
                if (response.data.is_pro) {
                    localStorage.setItem('is_pro', response.data.is_pro ? true : false);
                    alert("looks you are already pro")
                    window.location.reload();
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const checkCondition = async () => {
            await checkProRealtime.checkCondition(); // Alerts and reloads will be handled inside the service
        };
        checkCondition();
        fetchPlans();
        AudiInterest()
    }, []);
    return (
        <>
            <Modal
                size="md"
                show={UpgradeShow}
                onHide={handleUpgradeClose}
                aria-labelledby="example-modal-sizes-title-md"
                className="modal-size-craft upgrade"
                style={{ paddingLeft: "0px" }}
                centered
            // fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title className='ms-auto'>
                        {/* hyderabadcastingclub */}
                        <div className="logo justify-content-center">
                            <img src={require('../Pages/images/hcclogo.webp')} className="logo-image" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="success-icon p-0">
                    {/* <div className='upgrade-content'>
                        {ScreenVisbile === "plans" ? <div>
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                                <div className="auditons-status-btns">
                                    <button className="btn btn-secondary">Non-Professional</button>
                                    <button className="btn btn-info">Professional</button>
                                </div>
                            </div>
                            <h1 className='h1'>Go Pro Today – Exclusive Features, Unlimited Auditions!</h1>
                            <div className="toggle-container">
                                <div className='monthly'>Monthly</div>
                                <div class="form-switch">
                                    <input class="form-check-input" style={{ height: "20px", width: "40px" }} type="checkbox" id="flexSwitchCheckChecked" checked />
                                </div>
                                <div className='anually'>Annually</div>
                                <div className="discount-info">16% off annually. It's like 60 days free <span role="img" aria-label="smile">😊</span></div>
                            </div>
                            <div>
                                <div className='flex-item-card'>
                                    <div className="plan-card b-1">
                                        <div className='text-center'>
                                            <h4>Essential</h4>
                                            <span><span className="price">₹ 995</span><span> / month</span></span>
                                            <p className="Anual-price">₹11,940 / year</p>
                                            <p className="description">You save ₹3,600 a year</p>
                                            <button className="btn btn-shortlist shortlist-icon" onClick={() => hnadlescreen("Essential", "995", "11,940", "3,600", "1,295")}><p ><b>Buy Now</b></p></button>
                                        </div>
                                        <ul className='features-list'>
                                            <li>4 charts per tab</li>
                                            <div className='statusIndicator'>
                                                <div style={{ background: "#000", height: "100%", width: "20%" }}>

                                                </div>
                                            </div>
                                            <li>10 indicators per chart</li>
                                            <div className='statusIndicator'>
                                                <div style={{ background: "#000", height: "100%", width: "15%" }}>

                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="plan-card">
                                        <div className='text-center'>
                                            <h4>Plus</h4>
                                            <span>
                                                <span className="price">₹ 1,995</span>
                                                <span> / month</span></span>
                                            <p className="Anual-price">₹ 23,940 / year</p>
                                            <p className="description">You save ₹6,000 a year</p>
                                            <button className="btn btn-shortlist shortlist-icon" onClick={() => hnadlescreen("Plus", "1,995", "23,940", "6,000", "1,295")}><p ><b>Buy Now</b></p></button>
                                        </div>
                                        <ul className='features-list'>
                                            <li>4 charts per tab</li>
                                            <div className='statusIndicator'>
                                                <div style={{ background: "#000", height: "100%", width: "30%" }}>

                                                </div>
                                            </div>
                                            <li>10 indicators per chart</li>
                                            <div className='statusIndicator'>
                                                <div style={{ background: "#000", height: "100%", width: "25%" }}>

                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="plan-card b-r">
                                        <div className='text-center'>
                                            <h4>Premium</h4>
                                            <span><span className="price">₹ 3,995</span><span> / month</span></span>
                                            <p className="Anual-price">₹47,940 / year</p>
                                            <p className="description">You save ₹12,000 a year</p>
                                            <button className="btn btn-shortlist shortlist-icon" onClick={() => hnadlescreen("Premium", "3,995", "47,940", "12,000", "1,295")}><p ><b>Buy Now</b></p></button>
                                        </div>
                                        <ul className='features-list'>
                                            <li>8 charts per tab</li>
                                            <div className='statusIndicator'>
                                                <div style={{ background: "#000", height: "100%", width: "45%" }}>

                                                </div>
                                            </div>
                                            <li>25 indicators per chart</li>
                                            <div className='statusIndicator'>
                                                <div style={{ background: "#000", height: "100%", width: "45%" }}>

                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> : <Plans setScreenVisbile={setScreenVisbile} BuyNowData={BuyNowData} setbuyNowData={setbuyNowData} />}
                    </div> */}
                    {JSON.parse(localStorage.getItem("is_pro")) ? (
                        <IsProComponent />
                    ) : (
                        ScreenVisbile === "plans" ? (
                            <div className='upgrade-auto'>
                                <header className="upgrade-header">
                                    <div>
                                        <h4>Go Pro Today</h4>
                                        <h4>Exclusive Features, Unlimited Auditions!</h4>
                                    </div>
                                    <p className='hide-mobile'>
                                        As a Pro member, you’ll get access to premium features that can skyrocket your career in the entertainment industry.
                                    </p>
                                </header>
                                <section className='container hide-for-desktop'>
                                    <h2 className='upgrade-h2'>Why Upgrade?</h2>
                                    <p>
                                        As a Pro member, you’ll get access to premium features that can skyrocket your career in the entertainment industry.
                                    </p>
                                </section>
                                <section className="container">
                                    <h2 className='upgrade-h2'>Here’s What You Get</h2>
                                    <ul className="feature-list">
                                        <li>🔓 Unlimited Audition Applications</li>
                                        <li>🔍 Priority in Search Results</li>
                                        <li>🎬 Exclusive Access to Premium Auditions</li>
                                        {/* <li>📧 Send Unlimited Messages</li> */}
                                        <li>🚫 No Ads for a Seamless Experience</li>
                                        <li>🚀 Early Access to New Features</li>
                                    </ul>
                                </section>
                                <section className="cta-section">
                                    <h2>Don’t Miss Out – Upgrade to Pro Now!</h2>
                                    <div onClick={() => hnadlescreen("", "199", "499", "1499", "0")}>
                                        <p className='hide-mobile'>
                                            With Pro, you get the tools and visibility to take your acting career to the next level. Make it count!
                                        </p>
                                        <p><strong style={{ color: "#FFF" }}>₹{(plans?.price / plans?.duration_months).toFixed(0)} / month</strong></p>
                                    </div>
                                </section>
                            </div>
                        ) : (
                            <Plans
                                setScreenVisbile={setScreenVisbile}
                                BuyNowData={BuyNowData}
                                setbuyNowData={setbuyNowData}
                            />
                        )
                    )}

                </Modal.Body>
            </Modal >
        </>
    )
}

export { UpgradePage }

import React, { useEffect, useState } from "react";
import { Topbar, Navbar, Categories, AdRight, AdPost, Footer, Stories, DeletePopupModal } from "../Components";
import { NavLink, Outlet } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPage } from "./PostsPage";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Modal from 'react-bootstrap/Modal';
import { MdDeleteOutline } from "react-icons/md";
import CustomTop from "./CustomTop";
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal"
import { CraftsList } from "./CraftsList";
const View24Crafts = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [showApply, setshowApply] = useState(false)
    const [FrameData, SetFrameData] = useState([])
    const [UserFrameData, SetUserFrameData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    const filteredFrames = FrameData.filter(frame =>
        frame.frame_name.toLowerCase().includes(searchTerm.toLowerCase())
    );



    function applycraft(craftid) {
        let applyflag = false;
        UserFrameData.forEach((uframe, idx) => {
            if (uframe.role === craftid) {
                alert("You have applied already, please review in below table")
                setshowApply(false)
                applyflag = true;
            }
        })
        if (!applyflag) {
            axiosConfig.post("/accounts/user-frames/", { "role": craftid, }).then(res => {
                console.log(res)
                GetUserFramesData()
                setshowApply(false)
            }).catch(error => {
                console.log(error.response.data.error)
            })
        }
    }

    function submitApproval(id) {
        axiosConfig.patch(`/accounts/user-frames/${id}/`, { "frame_status": "Waiting for Approval", }).then(res => {
            console.log(res)
            GetUserFramesData()
        }).catch(error => {
            console.log(error.response.data.error)
        })
    }
    function HandleDelete(id) {
        console.log(id, "id")
        axiosConfig.delete(`/accounts/user-frames/${id}/`).then(res => {
            console.log(res)
            GetUserFramesData()
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")
        }).catch(error => {
            console.log(error.response.data.error)
            setDeletepopupType("error")
        })
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/role-24-frames/?page_size=100`)
            console.log(responce)
            SetFrameData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }

    }
    const GetUserFramesData = async () => {
        setLoading(true)

        try {
            const responce = await axiosConfig.get(`/accounts/user-frames/`)
            console.log(responce)
            SetUserFrameData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }

    }
    const [RowId, SetRowId] = useState()
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }

    useEffect(() => {
        GetData();
        GetUserFramesData();
    }, [])
    return (
        <>
            {/* <Topbar /> */}
            <CustomTop />
            <div className="main-container">
                <Navbar />
                <CraftsList />
                {/* <div className="content-area">
                    <div className="crafts-table">
                        <div className="tbhead">
                            <div className="row">
                                <div className="col-9">
                                    <h6>List of 24 Crafts</h6>
                                </div>
                                <div className="col-3">
                                    <button type="button" className="btn btn-success apply-new-craft" onClick={() => setshowApply(true)} >Apply New Craft</button>
                                </div>
                            </div>
                        </div>
                        <div className="customtable-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr</th>
                                        <th>Craft Name</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UserFrameData.map((frame, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{frame.role_name}</td>
                                                <td>{frame.created_at}</td>
                                                <td>{frame.comments}</td>
                                                <td>{frame.frame_status}</td>
                                                <td>
                                                    {frame.frame_status === "Peniding Submission" ? (
                                                        <>
                                                            {frame.forms.allforms.map((form, idx) => {
                                                                return (
                                                                    <>
                                                                        {form.datastatus ? (
                                                                            <>
                                                                                <NavLink to={`/24crafts/edit/${frame.role_name}/${form.form_slug}`} >
                                                                                    <div className="action-items">
                                                                                        Edit {form.form_name}
                                                                                    </div>
                                                                                </NavLink>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <NavLink to={`/24crafts/${frame.role_name}/${form.form_slug}`} >
                                                                                    <div className="action-items">
                                                                                        Fill {form.form_name}
                                                                                    </div>
                                                                                </NavLink>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                            {frame.forms.alldatastatus && (
                                                                <>
                                                                    <button type="button" className="btn btn-success mr-2" onClick={() => submitApproval(frame.id)}>Submit For Approval</button>
                                                                    <button type="button" className="btn btn-primary" onClick={() => DeleteAlert(frame.id)}><MdDeleteOutline /></button>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <button type="button" className="btn btn-primary" onClick={() => DeleteAlert(frame.id)}><MdDeleteOutline /></button>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
                {deletePopupType !== "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
                {
                    AlertDeletePopupType === "success" &&
                    <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                }
                <Modal
                    size="lg"
                    show={showApply}
                    onHide={() => setshowApply(false)}
                    aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Body className="success-icon">
                        <>
                            <div className="row">
                                <div className="col-8">
                                    <h5>Apply for 24 Craft</h5>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Craft Name"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="crafts-grid">
                                {filteredFrames.map((frame, index) => {
                                    return (
                                        <div className="craft-item" onClick={(e) => applycraft(frame.id)}>
                                            <p>{frame.frame_name}</p>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" className="btn btn-primary" onClick={() => setshowApply(false)}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
                <div className="right-area">

                </div>
            </div >
            <Footer />
        </>
    )

}
export { View24Crafts }
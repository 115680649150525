import React, { useState, useEffect } from "react";
import "./CustomTop.css";
import { useLocation, NavLink } from "react-router-dom";
import { BiAddToQueue, BiSearch } from "react-icons/bi";
import { MdOutlineHome, MdOutlineNotifications, MdOutlineMovieCreation } from "react-icons/md";
import { useAuth } from "../Utils/AuthContext";
import { useNavigate } from "react-router";
import Modal from 'react-bootstrap/Modal';
import { EditDp, Login, Signup } from "../Components/Login";
import { NewPost } from "./NewPost";
import { Notifications } from "./Notifications";
import { RiAccountCircleLine } from "react-icons/ri";
import { Tab, Tabs } from "react-bootstrap";
import { HiHome } from "react-icons/hi2";
import { CraftsModal } from "./CraftsModal";

const CustomMobile = () => {
    const location = useLocation();
    const Navigate = useNavigate();
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const [show, setShow] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [ProfileData, setProfileData] = useState({})
    const [isRead, setIsRead] = useState(false);
    const [page, setPage] = useState(1);
    const [AddPost, setAddPost] = useState(false)
    const [key, setKey] = useState('Login');

    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }
    const hcc_id = localStorage.getItem('hcc_id');
    const MobileLogin = () => {
        Navigate(`/profile/${hcc_id}`)
    }
    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        setProfile()
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true)
            setProfile()
        }
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    useEffect(() => {
        setProfile();
        CheckLogin();
        if (location.pathname === '/signup') {
            if (localStorage.getItem("token")) {
                Navigate("/")
                setloginView("")
                setloginShow(false)
            } else {
                console.log("test")
                setloginView("signup")
                setKey("Signup")
                setloginShow(true)
            }
            // setloginView("signup")
        }
    }, [])
    const handleClose = () => { setShow(false); setIsRead(false) }
    const handleShow = () => { setShow(true); setPage(1) }
    const [profilePic, setProfilepic] = useState(false)
    const [showApply, setshowApply] = useState(false)
    let profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const HandlePost = () => {
        console.log(localStorage.getItem("is_24_craft"))
        if (localStorage.getItem('token')) {
            if (profile_data && profile_data.profile_pic === null || "") {
                setProfilepic(true)
            } else if (localStorage.getItem("is_24_craft") === null) {
                setshowApply(true)
            } else {
                setAddPost(true)
            }
        } else {
            handleLoginShow();
        }
    }
    const handleprofilpicclose = () => {
        setProfilepic(false)
    }
    const handleDepartmentClose = () => {
        setshowApply(false)
    }
    useEffect(() => {
        if (key === 'Login') {
            setloginView('login');
        } else if (key === 'Signup') {
            setloginView('signup');
        }
    }, [key]);
    return (
        <>

            <div className="options-mobile">
                <div className="options-container-mobile">
                    <div className="mobile-icons-tab" onClick={() => Navigate('/')}>
                        <NavLink to="/">
                            <HiHome className="option-icon-mbl" size={24} />
                            <p>Home</p>
                        </NavLink>
                    </div>
                    <div className="mobile-icons-tab" onClick={() => Navigate('/s')}>
                        <NavLink to="/s">
                            <BiSearch className="option-icon-mbl" size={24} />
                            <p>Search</p>
                        </NavLink>
                    </div>
                    <div className="mobile-icons-tab" onClick={(e) => HandlePost()}>
                        <img src={require("../Components/Navbar/images/newpost.webp")} width="24px" className="option-icon-mbl" />
                        <p>Post</p>
                    </div>
                    <div className="mobile-icons-tab" onClick={() => Navigate('/auditions')}>
                        <NavLink to="/auditions">
                            {location.pathname === '/auditions' ? (
                                <img src={require("../Components/Navbar/images/Auditionactive.webp")} className="option-icon-mbl" />
                            ) : (
                                <img src={require("../Components/Navbar/images/audition.webp")} className="option-icon-mbl" />
                            )}
                            <p>Auditions</p>
                        </NavLink>
                    </div>
                    {isLoggedIn ?
                        <>
                            {/* <div className="mobile-icons-tab" onClick={handleShow}>
                                <MdOutlineNotifications className="option-icon" />
                                <p>Notice</p>
                            </div> */}
                            <div className="mobile-icons-tab" onClick={MobileLogin}>
                                <NavLink to={`/profile/${hcc_id}`}>
                                    <img src={ProfileData && ProfileData.profile_pic ? ProfileData.profile_pic : require("../Components/Post/images/male.jpg")} width="24" className="profile-pic-mobile-img" alt={`${ProfileData?.first_name} ${ProfileData?.last_name}`} />
                                    <p>Profile</p>
                                </NavLink>
                            </div>
                        </> : <>
                            <div className="mobile-icons-tab" onClick={isLoggedIn ? MobileLogin : handleLoginShow}>
                                <RiAccountCircleLine className="account-mobile" />
                                <p>Login</p>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
                    <div className="login-text-type">{loginView === "login" ? "Welcome back !" : "Create Your Account"}</div>
                </Modal.Header>
                <Modal.Body>
                    {loginView == "login" ? (
                        <>
                            <Login handleLoginEvent={handleLoginEvent} />
                            <div className="switchtag" onClick={() => setloginView("signup")} ><span>New user?</span> Sign Up</div>
                        </>
                    ) : (
                        <>
                            <Signup handleLoginEvent={handleLoginEvent} />
                            <div className="switchtag" onClick={() => setloginView("login")} ><span>Already have an account?</span> Click to Login</div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
            <Notifications show={show} setShow={setShow} handleClose={handleClose} ProfileData={ProfileData} isRead={isRead} setIsRead={setIsRead} setPage={setPage} page={page} />
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add your profile to get started</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                </Modal.Body>
            </Modal>
            {showApply &&
                <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleDepartmentClose} />
            }
        </>
    )
}

export default CustomMobile;
import React, { useState, useEffect } from "react";
import "./Products.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import Product from "./Product";
import SubProduct from "./SubProduct";
const ProductsList = (props) => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [Data, SetData] = useState([])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    let page = 1;
    let totalPages;

    let subcategory_id = props.subcategory_id
    const GetData = async (subcategory_id) => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/products/?subcategory=${subcategory_id}`)
            totalPages = Math.ceil(responce.data.count / 20);
            console.log(totalPages)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleInfiniteScroll = async () => {
        // console.log("scrollHeight" + document.documentElement.scrollHeight);
        // console.log("innerHeight" + window.innerHeight);
        // console.log("scrollTop" + document.documentElement.scrollTop);
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 500 >=
                document.documentElement.scrollHeight
            ) {
                if (page < totalPages) {
                    setLoading2(true)
                    page = page + 1
                    let resp = await axiosConfig.get(`/catlog/products/?subcategory=${subcategory_id}&page=${page}`)
                    SetData((Data) => [...Data, ...resp.data.results])
                    setLoading2(false)
                }else{
                    setLoading2(false)
                }
                
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetData(subcategory_id);
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [subcategory_id])

    return (
        <>
            {
                isMobile ?
                    <div className="products-container">
                        {loading ? (
                            <div className="loader-container">
                                <img src={require('../images/loader.gif')} alt="Loading..." />
                            </div>
                        ) : (
                            <>
                                {Data.length > 0 &&

                                    <div className="Catagery-data">
                                        <h2>{props.products_for}</h2>
                                        <div className="row mx-1">
                                            {
                                                Data.map((itemObj, index) => {
                                                    return (
                                                        <div className=" col-6 catagery-item-product" key={index}>
                                                            <SubProduct item={itemObj} index={index} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* another loader */}
                                        {loading2 && (
                                            <div className="loader-container">
                                                <img src={require('../images/loader.gif')} alt="Loading..." />
                                            </div>
                                        )}
                                    </div>
                                }
                            </>
                        )}
                    </div>
                    :
                    <div className="products-container">
                        {loading ? (
                            <div className="loader-container">
                                <img src={require('../images/loader.gif')} alt="Loading..." />
                            </div>
                        ) : (
                            <>
                                {Data.length > 0 &&
                                    <>
                                        <div className="container Catagery-data">
                                            <h2>{props.products_for}</h2>
                                            <div className="row">
                                                {
                                                    Data.map((itemObj, index) => {
                                                        return (
                                                            <div className="col-md-3 col-sm-6 catagery-item-product" key={index}>
                                                                <Product item={itemObj} index={index} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* another loader */}
                                            {loading2 && (
                                                <div className="loader-container">
                                                    <img src={require('../images/loader.gif')} alt="Loading..." />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </>
                        )}
                    </div>
            }
        </>
    )
}
export { ProductsList };
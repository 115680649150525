import React, { useEffect, useState } from "react";
import { Topbar, Navbar,Categories,AdRight,AdPost,Footer,Stories } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import { FormParser,PopupModal } from "../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPage } from "./PostsPage";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Masters from "../Components/masters.json"

const ViewCraft = () => {
    const {craftname,craftslug} = useParams();
    let MastersModal = Masters[craftslug]
    console.log(MastersModal);
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const [Error, SetError] = useState("")
    const [loading, setLoading] = useState(false)
    const [FrameData, SetFrameData] = useState([])
    const [QuestionsData, SetQuestionsData] = useState([])

    const formSubmit = (Data) => {
        console.log(Data)
            SetError([])
            axiosConfig.post(`/accounts/${craftslug}/`, Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                let errorData={}
                console.log(errorData)
                SetformErrorData(errorData)
                setpopupType("error")
            })
    }

    const GetData = async () => {
        setLoading(true)

        try {
            const responce = await axiosConfig.get(`/masters/role-24-frames/`)
            console.log(responce)
            SetFrameData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }

    }

    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
        <Topbar />
        <div className="main-container">
            <Navbar />
            
                <div className="content-area">
                    <div className="form-render">
                        <h5>Fill the following details to apply for {craftname} </h5>
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                        {popupType != "" &&
                            <PopupModal popupType={popupType} Navigation="/24crafts"/>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}
export {ViewCraft}
import React from "react";
import { Topbar, Navbar,Categories,AdRight,AdPost,Footer,Stories } from "../Components";
import { NavLink, Outlet } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPage } from "./PostsPage";
import { FormParser } from "../Components";
import Masters from "../Components/masters.json"
import axiosConfig from "../Service/axiosConfig";
import CustomTop from "./CustomTop";

import "./Pages.css"
const Explore = () => {

    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.get("/accounts/user-profiles/", {
            params: Data
          }).then(res => {
            console.log(res)
        }).catch(error => {
            console.log(error.response.data.error)
        })
    }

    return (
        <>
        {/* <Topbar /> */}
        <CustomTop />
        <div className="main-container homescreen">
            <Navbar />
                <div className="filters-container">
                    <FormParser modelObject={Masters.FilterForm} formSubmit={formSubmit} />
                </div>
                <div className="content-area">
                    <div className="stories-container">
                        {/* <Stories /> */}

                        
                    </div>
                    <div className="posting-container">
                        {/* <PostsPage /> */}
                    </div>
                </div>
            </div>
            {/* <div className="info-banner">
                <div className="info-banner-container">
                    <p>Delivery In 10 Mins</p>
                </div>
            </div> */}

            <Footer />
        </>
    )

}
export {Explore}
import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPage } from "./PostsPage";
import "./Pages.css";
import axiosConfig from "../Service/axiosConfig";
import Modal from "react-bootstrap/Modal";
import { MdDeleteOutline } from "react-icons/md";
import CustomTop from "./CustomTop";

const CraftsModal = ({ showApply, setshowApply }) => {

    const [loading, setLoading] = useState(false);
    const [FrameData, SetFrameData] = useState([]);
    const [UserFrameData, SetUserFrameData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedId, setSelectedId] = useState([]);
    const [Data, setData] = useState({});
    const handleClose = () => {
        console.log("craft close")
        setshowApply(false)
        RefreshLogin()
    }

    const filteredFrames = FrameData.filter(frame =>
        frame.frame_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCraftSelect = (id) => {
        let updatedSelectedIds = [...selectedId];
        let updatedUserFrameData = [...UserFrameData];

        if (updatedSelectedIds.includes(id)) {
            updatedSelectedIds = updatedSelectedIds.filter(selectedId => selectedId !== id);
        } else if (updatedUserFrameData.some(uframe => uframe.role === id)) {
            updatedUserFrameData = updatedUserFrameData.filter(uframe => uframe.role !== id);
        } else {
            if (updatedSelectedIds.length + updatedUserFrameData.length >= 3) {
                alert("You can select a maximum of 3 crafts only.");
                return;
            }
            updatedSelectedIds.push(id);
        }

        setSelectedId(updatedSelectedIds);
        SetUserFrameData(updatedUserFrameData);
    };

    const applycraft = () => {
        const allSelectedIds = [
            ...selectedId,
            ...UserFrameData.map(uframe => uframe.role)
        ];

        if (allSelectedIds.length === 0) {
            alert("No crafts selected.");
            return;
        }

        const formData = new FormData();
        allSelectedIds.forEach(id => formData.append("roles", id));

        axiosConfig.post("/accounts/user-frames/create_update_multi_roles/", formData)
            .then(res => {
                console.log(res);
                setshowApply(false);
                handleClose();
                setSelectedId([]);
                if (localStorage.getItem("is_24_craft") === null) {
                    RefreshLogin();
                }
            })
            .catch(error => {
                console.log(error.response.data.error);
            });
    };

    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/masters/role-24-frames/?page_size=100`);
            console.log(response);
            SetFrameData(response.data.results);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const GetUserFramesData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/user-frames/`);
            console.log(response);
            SetUserFrameData(response.data.results);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const RefreshLogin = async () => {
        try {
            const response = await axiosConfig(`/accounts/api/refresh_login/`);
            console.log(response);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('profile_data', JSON.stringify(response.data.profile_data));
            localStorage.setItem('is_pro', response.data.is_pro ? true : false);
            if (response.data.is_24_craft) {
                localStorage.setItem('is_24_craft', response.data.is_24_craft);
                localStorage.setItem('frames_24', response.data.frames_24);
                localStorage.setItem('hcc_id', response.data.hcc_id);
            }
            window.location.reload()
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        GetData();
        GetUserFramesData();
    }, []);

    return (
        <>
            <Modal
                size="lg"
                show={showApply}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
                className="modal-size-craft"
            >
                <Modal.Header closeButton className="craft-title">
                    <div className="d-flex justify-content-between w-100 craft-save">
                        <Modal.Title>Select Department</Modal.Title>
                        <button className="btn btn-primary" onClick={applycraft}>Save</button>
                    </div>
                </Modal.Header>
                <Modal.Body className="success-icon">
                    <p className="hide-for-desktop">Please select up to 3 crafts from the list below that best represent your expertise.</p>
                    <p className="hide-mobile">Please select up to 3 crafts from the list below that best represent your expertise. This will help us tailor opportunities and connections that match your skills and interests.</p>
                    <>
                        <div className="row hide-mobile" style={{ justifyContent: "flex-end" }}>
                            <div className="col-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Craft Name"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="hide-for-desktop">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Craft Name"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="crafts-grid">
                            {filteredFrames.map((frame, index) => {
                                const isSelected = selectedId.includes(frame.id) || UserFrameData.some(uframe => uframe.role === frame.id);
                                return (
                                    <div
                                        key={index}
                                        className={`craft-item ${isSelected ? 'selected' : ''}`}
                                        onClick={() => handleCraftSelect(frame.id)}
                                    >
                                        {isSelected && <img src={require("./images/craftcheck.png")} className="craft-check" />}
                                        <img src={frame?.frame_image} />
                                        <p>{frame.frame_name}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer className="hide-mobile">
                    <button className="btn btn-primary" onClick={applycraft}>Save Selections</button>
                    <button variant="primary" className="btn btn-primary" onClick={() => setshowApply(false)}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { CraftsModal };

import React from 'react';
import { Footer, Navbar } from '../Components';
import CustomTop from './CustomTop';

const AboutPage = () => {
    return (
        <>
            <CustomTop />
            <div className='main-container'>
                <Navbar />
                {/* <div className="container privacy-policy">
                    <h1 style={{ textAlign: 'center' }}>About Us</h1>
                    <p>The <strong>Hyderabad Casting Club,</strong> pioneering the field in India, is poised to become the foremost mobile application/brand, with a vision to lead the Media and Entertainment Industry through its innovative mobile-centric platform. Our role extends beyond being just a talent acquisition and engagement platform within the nation; we also stand as the premier specialized marketplace for talent services in the industry. In today's ever-evolving market landscape, traditional intermediaries for business hiring lack credibility online, while online directories for home and personal projects remain poorly organized and cataloged, leaving a significant gap in the market.</p>
                </div> */}
                <div className="container privacy-policy">
                    <h1 style={{ textAlign: 'center' }}>About Us</h1>
                    <p>
                        Welcome to Hyderabad Casting Club World, your premier platform connecting the diverse talents of the cinema and entertainment industry. We are dedicated to bringing together artists, models, directors, producers, and other professionals across the 24 crafts, offering a unique space to showcase their skills, collaborate, and discover new opportunities.
                    </p>

                    <h4>Our Mission</h4>
                    <p>
                        Our mission is to streamline the talent discovery and collaboration process in the cinema and entertainment industry. We aim to provide a dedicated platform where professionals from all crafts can connect, share their work, and grow together.
                    </p>

                    <h4>Our Vision</h4>
                    <p>
                        We envision becoming the go-to social media platform for cinema and entertainment professionals, facilitating seamless connections and fostering an environment of creativity and collaboration.
                    </p>

                    <h4>What We Offer</h4>
                    <ul>
                        <li><strong>Comprehensive Profiles:</strong> Create detailed profiles showcasing your skills, experience, and portfolio.</li>
                        <li><strong>Engagement Opportunities:</strong> Upload images, write bios, and post daily updates to engage with a community of like-minded professionals.</li>
                        <li><strong>Auditions and Applications:</strong> Discover auditions and apply with ease. Our platform simplifies the process, making it easier for talents to find and apply for opportunities.</li>
                        <li><strong>Pro/Premium Features:</strong> Enjoy enhanced features such as applying for auditions, posting videos, and messaging with a Pro/Premium subscription.</li>
                    </ul>

                    <h4>Our Journey</h4>
                    <p>
                        Founded by a team passionate about the entertainment industry, Hyderabad Casting Club World was created to address the gaps and challenges faced by professionals in connecting and collaborating. We understand the unique needs of each craft and are committed to providing a platform that caters to these needs, making talent discovery and collaboration more accessible than ever.
                    </p>

                    <h4>Join Us</h4>
                    <p>
                        Whether you are an established professional or just starting your journey in the cinema and entertainment industry, Hyderabad Casting Club World welcomes you. Join our growing community and take the next step in your professional journey.
                    </p>

                    <h4>Get in Touch</h4>
                    <p>
                        We are always here to help. If you have any questions or need assistance, feel free to reach out to us at <b>info@hyderabadcastingclub.com</b> or visit our <a href="#">Contact Us</a> page for more information.
                    </p>
                </div>

            </div>
            <Footer />
        </>

    );
};

export { AboutPage };

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { AudirequireShare } from './AudirequireShare';
import CustomTop from './CustomTop';
import { Footer, Navbar } from '../Components';

const AudiShare = () => {
    let { id } = useParams();
    const [Audition, setAudition] = useState({});
    const [loading, setLoading] = useState(false);
    console.log(id)
    const GetData = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/audition-requirements/${id}/`);
            setAudition(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        GetData()
    }, [id])
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page">
                <Navbar />
                <div className="content-area d-flex justify-content-center">
                    {Audition && <AudirequireShare Audition={Audition} />}
                </div>
            </div >
            <Footer />
        </>
    )
}

export { AudiShare }
import React from "react";
import { DashboardLayout, Navbar,Categories,ProductsList,Banners,Footer } from "../Components";
import { NavLink, Outlet ,useSearchParams} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchProducts } from "./SearchProducts";
import "./Pages.css"
const ProductSearch = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get("query");
    console.log(query)
    return (
        <>
            <Navbar search={query}/>
            <SearchProducts query={query}/>
            <Footer />
        </>
    )

}
export {ProductSearch}
import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";

const getLocaData = () => {
  const NewData = localStorage.getItem("state")
  if (NewData === null) {
    return []
  }
  else {
    return JSON.parse(NewData)
  }
}
const getLocaTotal = (state) => {
  const NewData = localStorage.getItem("total")
  if (NewData === null) {
    return 0
  }
  else {
    console.log(NewData)
    return parseFloat(NewData)

  }
}
const getLocalWishlists = (state) => {
  const NewData = localStorage.getItem("wishlists")
  if (NewData === null) {
    return []
  }
  else {
    return JSON.parse(NewData)
  }
}
const getLocalShortlists = (state) => {
  const NewData = localStorage.getItem("shortlists")
  if (NewData === null) {
    return []
  }
  else {
    return JSON.parse(NewData)
  }
}
const getLocalFollowers = (state) => {
  const NewData = localStorage.getItem("followers")
  if (NewData === null) {
    return []
  }
  else {
    return JSON.parse(NewData)
  }
}
const getLocalCOmments = (state) => {
  const NewData = localStorage.getItem("comments")
  if (NewData === null) {
    return []
  }
  else {
    return JSON.parse(NewData)
  }
}
const initialState = {
  products: getLocaData(),
  wishlists: getLocalWishlists(),
  shortlists: getLocalShortlists(),
  followers: getLocalFollowers(),
  comments: getLocalCOmments(),
  total: getLocaTotal(),
};
export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const { product, quantity } = action.payload;
      const existingProduct = state.products.find((p) => p.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += quantity
      } else {
        state.products.push({ ...product, quantity });
      }
      console.log(product, state)
      const basePrice = product.offer_price > 0 ? product.offer_price : product.price;
      const totalPrice = parseFloat(basePrice) + (isNaN(product.variant_Price) ? 0 : product.variant_Price);
      state.total += quantity * totalPrice;
      console.log(state.total, quantity, parseFloat(basePrice), product.variant_Price, totalPrice, "total")
      localStorage.setItem("total", state.total)
    },
    addWishlist: (state, action) => {
      const { productId, wish_id } = action.payload;

      const existingProduct = state.wishlists.find((p) => p.id === productId);
      if (!existingProduct) {
        const newProduct = { id: productId, wish_id: wish_id };
        state.wishlists.push(newProduct);
        localStorage.setItem("wishlists", JSON.stringify(state.wishlists));
      }
    },

    removeWishlist: (state, action) => {
      const { productId } = action.payload;

      const productIndexToRemove = state.wishlists.findIndex(
        (p) => p.id === productId
      );

      if (productIndexToRemove !== -1) {
        state.wishlists.splice(productIndexToRemove, 1);
        localStorage.setItem("wishlists", JSON.stringify(state.wishlists));
      }
    },
    addShortlist: (state, action) => {
      const { ShortlistId, short_id } = action.payload;

      const existingProduct = state.shortlists.find((p) => p.id === ShortlistId);
      if (!existingProduct) {
        const newProduct = { id: ShortlistId, short_id: short_id };
        state.shortlists.push(newProduct);
        localStorage.setItem("shortlists", JSON.stringify(state.shortlists));
      }
    },

    removeShortlist: (state, action) => {
      const { ShortlistId } = action.payload;
      const productIndexToRemove = state.shortlists.findIndex(
        (p) => p.id === ShortlistId
      );
      if (productIndexToRemove !== -1) {
        state.shortlists.splice(productIndexToRemove, 1);
        localStorage.setItem("shortlists", JSON.stringify(state.shortlists));
      }
    },
    removeProduct: (state, action) => {
      const { productId, quantity } = action.payload;
      const existingProduct = state.products.find((p) => p.id === productId);

      if (existingProduct) {
        if (existingProduct.quantity > quantity) {
          existingProduct.quantity -= quantity;
          const basePrice = existingProduct.offer_price > 0 ? existingProduct.offer_price : existingProduct.price;
          const totalPrice = parseFloat(basePrice) + (isNaN(existingProduct.variant_Price) ? 0 : existingProduct.variant_Price);
          state.total -= quantity * totalPrice;
          localStorage.setItem("total", state.total)
        } else {
          state.products = state.products.filter((p) => p.id !== productId);
          const basePrice = existingProduct.offer_price > 0 ? existingProduct.offer_price : existingProduct.price;
          const totalPrice = parseFloat(basePrice) + (isNaN(existingProduct.variant_Price) ? 0 : existingProduct.variant_Price);
          state.total -= quantity * totalPrice;
          localStorage.setItem("total", state.total)
        }
      }
    },
    addFollower: (state, action) => {
      const { FollowId, Follow_id } = action.payload;

      const existingProduct = state.followers.find((p) => p.id === FollowId);
      if (!existingProduct) {
        const newProduct = { id: FollowId, Follow_id: Follow_id };
        state.followers.push(newProduct);
        localStorage.setItem("followers", JSON.stringify(state.followers));
      }
    },
    removeFollower: (state, action) => {
      const { FollowId } = action.payload;
      const productIndexToRemove = state.followers.findIndex(
        (p) => p.id === FollowId
      );
      if (productIndexToRemove !== -1) {
        state.followers.splice(productIndexToRemove, 1);
        localStorage.setItem("followers", JSON.stringify(state.followers));
      }
    },
    AddComment: (state, action) => {
      const { productId, wish_id } = action.payload;
      const existingProduct = state.comments.find((p) => p.id === productId);
      if (!existingProduct) {
        const newProduct = { id: productId, wish_id: wish_id };
        state.comments.push(newProduct);
        localStorage.setItem("comments", JSON.stringify(state.comments));
      }
    },
    RemoveComment: (state, action) => {
      const { productId } = action.payload;
      const productIndexToRemove = state.comments.findIndex(
        (p) => p.id === productId
      );
      if (productIndexToRemove !== -1) {
        state.comments.splice(productIndexToRemove, 1);
        localStorage.setItem("comments", JSON.stringify(state.comments));
      }
    },
    clearCart: (state) => {
      state.products = [];
      state.total = 0;
      state.wishlists = [];
      state.shortlists = [];
      state.followers = [];
      state.comments = [];
    },
  },

});
export const selectCartProducts = state => state.cart.products
export const selectWishlists = state => state.cart.Wishlists
export const selectShortlists = state => state.cart.Shortlists
export const selectFollowers = state => state.cart.followers
export const selectComments = state => state.cart.comments
export const selectCartTotal = state => state.cart.total

export const { addProduct, removeProduct, clearCart, addWishlist, removeWishlist, addShortlist, removeShortlist, addFollower, removeFollower, AddComment, RemoveComment } = cartSlice.actions;

export default cartSlice.reducer;
import axiosConfig from "../Service/axiosConfig";

const checkProRealtime = {
  async checkCondition() {
    try {
        const response = await axiosConfig(`/accounts/api/refresh_login/`)
        if(response.data.is_pro){
            if(localStorage.getItem('is_pro') !== response.data.is_pro.toString()){
                localStorage.setItem('is_pro', response.data.is_pro ? true : false);
                alert("looks you are already pro member")
                window.location.reload();
            }
        }else{
            if(localStorage.getItem('is_pro') === "true"){
                localStorage.setItem('is_pro', "false");
                alert("looks your subscription expired")
                window.location.reload();
            }
        }
    } catch (error) {
      console.error("API call failed:", error);
    }
  },
};

export default checkProRealtime;

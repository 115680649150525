import React, { useState, useEffect } from "react"
import "./MyProjects.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";
import Masters from "../masters.json"
import { FormParser, PopupModal } from "..";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineModeEditOutline, MdDeleteOutline } from "react-icons/md";


const MyProjects = ({ ProfileData, can_edit_profile }) => {
    const [Data, SetData] = useState([])
    const [EditFormData, SetEditFormData] = useState([])
    const [DeleteEnabled, SetDeleteEnabled] = useState(false)
    const [ModelShow, setModelShow] = useState(false);
    const handleEditProfileClose = () => {
        setModelShow(false);
        SetDeleteEnabled(false);
    }


    const [Error, SetError] = useState("")
    const [formerrorData, SetformErrorData] = useState({})
    const [popupType, setpopupType] = useState("");
    const [SuccessMessage, setSuccessMessage] = useState("");

    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/previous-projects/?profile=${ProfileData.id}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const [loading, setLoading] = useState(false)

    const formSubmit = (FormData) => {
        console.log(FormData)
        SetError([])
        if (FormData.id) {
            axiosConfig.patch(`/accounts/previous-projects/${FormData.id}/`, FormData).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                let errorData = {}
                console.log(errorData)
                SetformErrorData(errorData)
                setpopupType("error")
            })
        } else {
            axiosConfig.post(`/accounts/previous-projects/`, FormData).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                let errorData = {}
                console.log(errorData)
                SetformErrorData(errorData)
                setpopupType("error")
            })
        }
    }

    const successEvnet = (data) => {
        setModelShow(false)
        GetData();
        setpopupType("")
    }

    const DeleteProject = (id) => {
        axiosConfig.delete(`/accounts/previous-projects/${id}/`).then(res => {
            console.log(res)
            setSuccessMessage("Project Deleted Successfully !")
            setpopupType("success")
        }).catch(error => {
            let errorData = {}
            console.log(errorData)
            SetformErrorData(errorData)
            setpopupType("error")
        })
    }
    useEffect(() => {
        GetData();
    }, [])

    function getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }
    return (
        <>
            {loading ? (
                <div className="loader-container">
                    <img src={require('./images/loader.gif')} alt="Loading..." />
                </div>
            ) : (
                <div className="container">
                    {can_edit_profile &&
                        <div className="flri">
                            <button className="btn btn-default mb-2" onClick={(e) => { setModelShow(true); SetEditFormData([]); }} >Add New Project</button>
                        </div>
                    }
                    <div className="row">
                        {Data && Data.map((project, index) => {
                            return (
                                <div className="col-md-6 col-margin ">
                                    <iframe width="100%" height="315" src={'//www.youtube.com/embed/' + getId(project.url)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div className="project-info mb-3" style={{ gap: '18px' }}>
                                        <p ><span style={{ fontWeight: '700' }}>Project:</span> {project.project_type ? project.project_type : ''}</p>
                                        <p>{project.role && <span style={{ fontWeight: '700' }}>{project.role} for</span>} {project.project_title && <span> {project.project_title}</span>} {project.project_year && `(${project.project_year})`}</p>
                                        {can_edit_profile &&
                                            <div className="project-actions">
                                                <button className="btn btn-default" onClick={(e) => { setModelShow(true); SetEditFormData(project); }}><MdOutlineModeEditOutline /></button>
                                                <button className="btn btn-default" onClick={(e) => { setModelShow(true); SetEditFormData(project); SetDeleteEnabled(true) }}><MdDeleteOutline /></button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>

                    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={ModelShow} onHide={handleEditProfileClose} className="edit-profile-popup">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {DeleteEnabled ? "Delete Project" :
                                    <>
                                        {EditFormData.id ? "Edit" : "Add New"} Project
                                    </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                <FormParser modelObject={Masters.PreviousProjects} formData={EditFormData} formSubmit={formSubmit} error={Error} readonly={DeleteEnabled} />

                                {DeleteEnabled &&
                                    <>
                                        <p>Are you sure want to delete this project: </p>
                                        <button className="btn btn-danger" onClick={(e) => { DeleteProject(EditFormData.id) }}>Yes Delete this project</button>
                                    </>
                                }
                                {popupType != "" &&
                                    <>
                                        {SuccessMessage ?
                                            <PopupModal popupType={popupType} successEvnet={successEvnet} successmsg={SuccessMessage} />
                                            :
                                            <PopupModal popupType={popupType} successEvnet={successEvnet} />
                                        }
                                    </>
                                }
                            </>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </>
    )
}
export { MyProjects };
import React, { useEffect, useState } from 'react';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import axiosConfig from "../Service/axiosConfig";
import { onConfirmPlan, getExistingOrder, initiatePayment } from './PaymentFunctions';
import { PopupModal } from '../Components';
const Plans = ({ setScreenVisbile, BuyNowData, setbuyNowData }) => {
    const [price, setPrice] = useState();
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paymentLoad, setPaymentLoad] = useState(false);
    const [profile_data, setProfile_data] = useState({});
    const [order_id, setOrder_Id] = useState("");
    const [popupType, setpopupType] = useState("");
    const handlePriceChange = (plan) => {
        setPrice(plan.price);
        setbuyNowData({ ...BuyNowData, plan });
        onConfirmPlan && onConfirmPlan(profile_data?.profile_id, plan?.id, setOrder_Id)
    };

    const fetchPlans = async () => {
        try {
            setLoading(true);
            const response = await axiosConfig.get('/masters/plans/');
            setPlans(response.data?.results);
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
            setLoading(false);
        }
    };
    const checkExistingOrder = async (profileId) => {
        try {
            const response = await getExistingOrder(profileId); // Fetch existing orders
            if (response && response.length > 0) {
                const order = response[0]; // Get the first order;
                setPrice(order?.net_amount)
                setOrder_Id(order?.id)

            }
        } catch (error) {
            console.error('Error checking existing order:', error);
        }
    };

    useEffect(() => {
        fetchPlans();
        const storedProfileData = JSON.parse(localStorage.getItem('profile_data'));
        setProfile_data(storedProfileData)
        checkExistingOrder(storedProfileData?.profile_id)
    }, []);
    return (
        <div className="plans-content container">
            <div onClick={() => setScreenVisbile("plans")} style={{ cursor: "pointer" }}>
                <p><MdOutlineArrowBackIos size={16} /> Back</p>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <h4 style={{ fontWeight: "bold" }}>Choose Your Plan</h4>
                    <div style={{ marginTop: "25px" }}>
                        {loading ? (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FaSpinner size={24} className="spinner" />
                            </div>
                        ) : (
                            plans.map((plan) => (
                                <div key={plan.id} className='flex-item-container' style={{ marginTop: "15px" }}>
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id={`flexRadioDefault${plan.id}`}
                                                checked={price === plan.price}
                                                onChange={() => handlePriceChange(plan)}
                                            />
                                            <label className="form-check-label" htmlFor={`flexRadioDefault${plan.id}`}>
                                                {plan.name}
                                            </label>
                                            {plan.description && (
                                                <label className="form-check-label discount">
                                                    {plan.description}
                                                </label>
                                            )}
                                        </div>
                                        <div className="price-msg">₹{(plan?.price / plan?.duration_months).toFixed(0)} / month{plan.description}</div>
                                    </div>
                                    <div>
                                        <div className='price'>
                                            <span>₹ {plan.price} / {plan.duration_months === 1 ? 'month' : `${plan.duration_months} months`}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="card-buy">
                        <div className='flex-item'>
                            <h6>{BuyNowData.Title} Plan</h6>
                            <p>₹ {price}</p>
                        </div>
                        <div className='flex-item' style={{ border: "none", marginTop: "10px", alignItems: "normal", marginBottom: "25px" }}>
                            <div>
                                <div>Total</div>
                                <div style={{ fontSize: "12px", fontWeight: "400", color: "gray" }}>
                                    Billed every {plans.find(plan => plan.price === price)?.duration_months === 1 ? 'month' : `${plans.find(plan => plan.price === price)?.duration_months} months`}
                                </div>
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: "700" }}>
                                ₹ {price}
                            </div>
                        </div>
                        <button className="btn btn-shortlist shortlist-icon" onClick={() => initiatePayment(order_id, setpopupType, setPaymentLoad)} disabled={paymentLoad}>{paymentLoad ? <FaSpinner className="loading-icon" /> : <p><b>Pay Now</b></p>}</button>
                        {/* <button className="btn btn-shortlist shortlist-icon" disabled>{paymentLoad ? <FaSpinner className="loading-icon" /> : <p><b>Pay Now</b></p>}</button> */}
                    </div>
                </div>
            </div>
            {popupType !== "" && (
                <PopupModal popupType={popupType} setPopupType={setpopupType} successmsg="Payment Successful! Thank you for upgrading to Premium" error="Payment Failed. Unfortunately, your payment could not be processed" Navigation="" />
            )}
        </div>
    );
};

export default Plans;

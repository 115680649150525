import React from "react";
import { DashboardLayout, FormParser } from "../Components";
import { NavLink, Outlet } from "react-router-dom";

import "./Pages.css"
const Dashboard = () => {

    return (
        <>
            <h1>welcome dashboard</h1>
        </>
    )

}
export {Dashboard}
import React, { useState } from 'react';
import axiosConfig from '../../Service/axiosConfig';
import AvatarEditor from 'react-avatar-editor';
import { CraftsModal } from '../../Pages';
import { useAuth } from '../../Utils/AuthContext';
import Resizer from 'react-image-file-resizer';

const EditDp = ({ ProfileData, handleLoginEvent, showApply, setshowApply }) => {
  const [Data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    setData({ ...Data, profilePic: file });
  };
  const handleClose = () => {
    console.log("craft close")
    // setshowApply(false)
    // RefreshLogin()
  }

  const handleSave = async () => {
    if (editor) {
      const canvas = editor.getImage();
      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            // Resize the image
            Resizer.imageFileResizer(
              blob,
              400, // maxWidth
              400, // maxHeight
              'JPEG', // compressFormat (JPEG/PNG/WEBP)
              100, // quality
              0, // rotation
              async (resizedBlob) => {
                if (resizedBlob) {
                  const formData = new FormData();
                  formData.append('profilePic', resizedBlob);
                  axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
                  try {
                    await axiosConfig.post('/accounts/api/editprofile/', formData);
                    let profile_data = JSON.parse(localStorage.getItem("profile_data"));
                    if (profile_data?.profile_pic !== null && profile_data?.profile_pic !== "") {
                      console.log("data");
                      RefreshLogin();
                    } else {
                      console.log("newdata");
                      handleLoginEvent()
                      setshowApply(true);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }
              },
              'blob' // outputType (optional: base64 or blob)
            );
          } catch (error) {
            console.log('Resizing error:', error);
          }
        }
      });
    }
  };

  const RefreshLogin = async () => {
    try {
      const response = await axiosConfig(`/accounts/api/refresh_login/`)
      console.log(response)
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('profile_data', JSON.stringify(response.data.profile_data));
      localStorage.setItem('is_pro', response.data.is_pro ? true : false);
      if (response.data.is_24_craft) {
        localStorage.setItem('is_24_craft', response.data.is_24_craft);
        localStorage.setItem('frames_24', response.data.frames_24);
        localStorage.setItem('hcc_id', response.data.hcc_id);
      }
      handleLoginEvent();
      window.location.reload();
    }
    catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="edit-profile-container">
              {image && (
                <AvatarEditor
                  ref={(ref) => setEditor(ref)}
                  image={image}
                  width={300}
                  height={300}
                  border={50}
                  color={[255, 255, 255, 0.8]} // RGBA
                  scale={scale}
                  onImageChange={() => { }}
                />
              )}
            </div>
          </div>
          {image &&
            <div className="col-sm-12">
              <div className="form-group">
                <div className='zoomaction'>
                  <label>Zoom:</label>
                  <input
                    type="range"
                    min="1"
                    max="3"
                    step="0.1"
                    value={scale}
                    onChange={(e) => setScale(parseFloat(e.target.value))}
                  />
                </div>
              </div>
            </div>
          }
          <div className="col-sm-12">
            <div className="form-group">
              <label>Profile Picture:</label>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                name="profilePic"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        <button onClick={handleSave} className="btn btn-primary">
          Save
        </button>
      </div>
      {showApply &&
        <CraftsModal showApply={showApply} setshowApply={setshowApply} />
      }
    </>
  );
};

export { EditDp };

import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import axiosConfig from "../Service/axiosConfig"
import { useSelector, useDispatch } from "react-redux";
import { AddComment, RemoveComment } from "../redux/Slices/cartSlice";

const CommentLike = (props) => {

    let { productId, setIsDeleted, updateLikesCount } = props;
    // console.log(props)
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const CommentLike = (id) => {
        if (localStorage.getItem('token')) {
            let profile_data = JSON.parse(localStorage.getItem('profile_data'))
            axiosConfig.post(`/accounts/comment-likes/`, { user: profile_data.id, comment: id })
                .then(response => {
                    dispatch(AddComment({ productId: id, wish_id: response.data.id }));
                    console.log("Product added to wishlist:", response.data);
                    console.log("wish id:", cart.comments, productId)
                    // updateLikesCount("like")
                })
                .catch(error => {
                    console.error("Error adding product to wishlist:", error);
                })
        } else {
            handleLoginShow()
        }
    };
    const RemoveCommentLike = () => {
        let wishlists = JSON.parse(localStorage.getItem('comments'))
        let wishlistItem = wishlists.find((p) => p.id === productId);
        console.log(wishlistItem.wish_id)
        if (wishlistItem.wish_id) {
            axiosConfig.delete(`/accounts/comment-likes/${wishlistItem.wish_id}/`)
                .then(response => {
                    dispatch(RemoveComment({ 'productId': productId }));
                    // updateLikesCount("dislike")
                    // setIsDeleted(true)
                    console.log("remove product from wishlist", response)

                })
                .catch(error => {
                    console.error("Error removing product from wishlist:", error);
                })
        }

    };
    console.log(cart.comments.some((p) => p.id === productId), productId)
    return (
        <>

            {cart.comments.some((p) => p.id === productId) ? (
                <AiFillHeart size={12} onClick={() => RemoveCommentLike()} />
            ) : (
                <AiOutlineHeart size={12} onClick={() => CommentLike(productId)} />
            )}
        </>
    )
}
export { CommentLike }
import React, { useState, useEffect } from "react"
import "./Stories.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink, useNavigate } from 'react-router-dom';
import Slider from "react-slick";


const Stories = () => {

    var settings = {
        centerMode: true,
        centerPadding: '60px',
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 766,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            }
        ]
    };


    const [Data, SetData] = useState([])

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/accounts/model-images/?is_story=true&is_active=true")
            SetData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
    }, [])

    // const Navigate = useNavigate()
    // const handleClick = (event) => {
    //     event.preventDefault();
    //     // Navigate(`/catlog/sub-categories/${Data.subcategory}`)
    //     console.log(event)
    // }


    return (
        <>
            <div className="storys-container">
                <div className="container">
                    <Slider {...settings}>
                        {Data.map((bannerItem) => {
                            return (
                                <div className="story-item" key={bannerItem.id}>
                                    <NavLink to={`${bannerItem.slug}`}>
                                        <div className="story-img">
                                            <img src={bannerItem.image} />
                                        </div>
                                    </NavLink>
                                </div>
                            )
                        })}


                    </Slider>
                </div>
            </div>
        </>
    )
}
export { Stories };
import React, { useState, useEffect } from "react";
import "./SubCategories.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";

const SubCategories = (props) => {
    const { category_id, category_slug } = props;
    const [loading, setLoading] = useState(false)
    const [Data, SetData] = useState([])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };


    const GetData = async (category_id) => {

        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/sub-categories/?category=${category_id}`)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData(category_id);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [category_id])

    return (
        <>{
            isMobile ?
                <div className="categories-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('../images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <>
                            {
                                Data.map((itemObj, index) => {
                                    return (
                                        <div className="subcat-item-container" key={index}>
                                            <NavLink to={`/${category_slug}/${itemObj.slug}`} >
                                                <div className="text-center ismobile-relclass relclass">
                                                        <img src={itemObj.image} />                                         
                                                        <p className="sub-p">{itemObj.name}</p>                                              
                                                </div>
                                            </NavLink>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )}
                </div> :

                <div className="categories-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('../images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <>
                            {
                                Data.map((itemObj, index) => {
                                    return (
                                        <div className="subcat-item-container" key={index}>
                                            <NavLink to={`/${category_slug}/${itemObj.slug}`} >
                                                <div className="row relclass">
                                                    <div className="col-3">
                                                        <img src={itemObj.image} />
                                                    </div>
                                                    <div className="col-9 sub-p-container">
                                                        <p className="sub-p">{itemObj.name}</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )}
                </div>
        }
        </>
    )
}
export { SubCategories };
import React from "react";
import { DashboardLayout, Navbar,Categories,ProductsList,SubCategories,Footer } from "../Components";
import "slick-carousel/slick/slick.css";
import { SubCategoriesgrid } from "../Components/SubCategories";
import "slick-carousel/slick/slick-theme.css";
import { NavLink,useParams } from "react-router-dom";

import "./Pages.css"
const CategoriesPage = (props) => {
    const {category_id,category_slug}=props;

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="catflexpage">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="subcategories-list gridlayout">
                                <SubCategoriesgrid category_id={category_id} category_slug={category_slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}
export {CategoriesPage}
import { useSearchParams } from 'react-router-dom';

export const useURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to update or remove URL parameters
  const setParam = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value && value !== '') {
      console.log(`Setting URL param: ${key} = ${value}`);
      updatedParams.set(key, value);
    } else {
      console.log(`Removing URL param: ${key}`);
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };

  // Function to get a specific URL parameter value
  const getParam = (key) => {
    const paramValue = searchParams.get(key);
    console.log(`Retrieved URL param: ${key} = ${paramValue}`);
    return paramValue || '';
  };
  const handleMultiSelect = (key, selectedItems) => {
    const selectedValues = selectedItems.map((item) => item).join(','); // Join selected values into a comma-separated string
    setParam(key, selectedValues); // Update URL parameter
  };

  // Function to get a list of selected values from a URL parameter
  const getMultiSelectParam = (key) => {
    const paramValue = getParam(key); // Get the parameter value from the URL
    if (paramValue) {
      // Remove square brackets and single quotes from the parameter value
      const formattedValue = paramValue.replace(/[\[\]']+/g, '');
      // Split the formatted value by commas and convert each item to a number
      return formattedValue.split(',').map(Number);
    }
  
    return []; // Return an empty array if no value is found
  };
  const clearSelection = (setSelectedId,name) => {
    setSelectedId('');
    setParam(name, ''); // Reset or remove the URL parameter
  };
  return { setParam, getParam, clearSelection, handleMultiSelect, getMultiSelectParam };
};

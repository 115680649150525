import React, { useEffect, useState } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { MobileNavBar } from "../Components/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineEditLocation } from "react-icons/md"
import { AiOutlineReconciliation, AiOutlineUser } from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig";
import { BiRupee } from "react-icons/bi";
import "./MyOrders.css";
const MyOrders = () => {
  const [loading, setLoading] = useState()
  const [Orders, setOrders] = useState([])
  const [ActiveUrl, setActiveUrl] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  let Navigate = useNavigate();
  const HandleNavigate = () => {
    Navigate("/account")
  }
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl)
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetData = async () => {
    setLoading(true)
    const response = await axiosConfig.get(`/accounts/orders/?page=1&user=${localStorage.getItem("userId")}`)
    setOrders(response.data.results)
    let count = Math.ceil(response.data.count / 20)
    let number = []
    for (let i = 1; i < count + 1; i++) {
      if (i != 1) {
        number.push(i)
      }
    }
    console.log(number)
    setTimeout(async function () {
      number.forEach(async (page) => {
        try {
          const response = await axiosConfig.get(`/accounts/orders/?page=${page}&user=${localStorage.getItem("userId")}`)
          setOrders(prevData => [...prevData, ...response.data.results])
          setLoading(false)
        }
        catch (error) {
          console.log(error)
          setLoading(false)
        }
      })
    }, 1000)
  }
  console.log(Orders)
  useEffect(() => {
    GetData();
    GetlocaData()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  return (
    <>
      {
        isMobile ? <MobileNavBar HandleNavigate={HandleNavigate} /> : <Navbar />
      }
      {
        isMobile ?
          <div className="container-fluid my-profile_mobile">
            <div className="row">
              <div className="col-12 col-sm-12 order-overflow">
                {Orders.length > 0 ? (Orders.map((order, index) => {
                  return (
                    <div>
                      <div className="row order" key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex gap-4 align-items-center">
                            <img src={require("./images/orderimg.png")} alt="" className="image" />
                            <div>
                              <h5 className="order_id">Order Id:{order.id}&nbsp;-&nbsp;₹{order.net_amount}</h5>
                              <p className="">Placed on {order.order_date}</p>
                            </div>
                            <div className="status_badge">
                              <div>{order.orderstatus}</div>
                            </div>
                            <div className="text-center">
                              <NavLink to={`${order.id}/${order.addresses}`}><button type="button" className="btn btn-outline-success">view details</button></NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })) : (<p className="no-orders">You have not ordered anything yet!!!</p>)}
              </div>
            </div>
          </div> :
          <div className="container my-profile_rn">
            <div className="row">
              <div className="col-lg-2 col-md-2 profile-main">
                <div className="profile-nav__details-block">
                  <div class="account-profile__img"></div>
                  <div class="account-profile__phone">{localStorage.getItem("number")}</div>
                </div>
                <nav className="profile-nav__list">
                  <ul className="list-unstyled">
                    <NavLink to="/account/addresses" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/addresses")}><li className="item-text"><span className="mx-2"><MdOutlineEditLocation /></span>My Addresses</li></NavLink>
                    <NavLink to="/account/orders" className={ActiveUrl === "/account/orders" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation /></span>My Orders</li></NavLink>
                    <NavLink to="/account/wallet" className={ActiveUrl === "/account/wallet" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee /></span>My wallet</li></NavLink>
                    <NavLink to="/logout" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser /></span>Logout</li></NavLink>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-10 col-md-10 order-overflow">
                {Orders.length > 0 ? (Orders.map((order, index) => {
                  return (
                    <div>
                      <div className="row order" key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex gap-4 align-items-center">
                            <img src={require("./images/orderimg.png")} alt="" className="image" />
                            <div>
                              <h5 className="order_id">Order Id:{order.id}&nbsp;-&nbsp;₹{order.net_amount}</h5>
                              <p className="">Placed on {order.order_date}</p>
                            </div>
                            <div className="status_badge">
                              <div>{order.orderstatus}</div>
                            </div>
                          </div>
                          <div className="text-center">
                            <NavLink to={`${order.id}/${order.addresses}`}><button type="button" className="btn btn-outline-success view-button">view details</button></NavLink>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })) : (<p className="no-orders">You have not ordered anything yet!!!</p>)}
              </div>
            </div>
          </div>
      }
    </>
  )
}
export { MyOrders }
import React, { useState } from "react";
import { MdPhotoLibrary, MdArrowBack } from "react-icons/md";
import axiosConfig from "../Service/axiosConfig";
import { Topbar, Navbar, Categories, AdRight, AdPost, Footer, Stories } from "../Components";
import { NavLink, Outlet } from "react-router-dom";
import { FormParser, PopupModal } from "../Components";
import CustomTop from "./CustomTop";
import { Modal } from "react-bootstrap";
import { compose } from "redux";
import FabricImageEditor from "../Components/Canvas/Canvas";

const NewPost = ({ AddPost, setAddPost }) => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedPhotosRaw, setSelectedPhotosRaw] = useState([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [caption, setCaption] = useState("");
  const [uploadStep, setUploadStep] = useState("initial"); // "initial" or "uploading"
  const [popupType, setpopupType] = useState("");
  const [loading, setLoading] = useState("")

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    const newPhotos = [...selectedPhotos];
    const newPhotosRaw = [...selectedPhotosRaw];
    for (let i = 0; i < files.length; i++) {
      newPhotos.push(URL.createObjectURL(files[i]));
      newPhotosRaw.push(files[i])
    }
    setSelectedPhotos(newPhotos);
    setSelectedPhotosRaw(newPhotosRaw);
    setUploadStep("uploading");
  };

  const handleNextPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % selectedPhotos.length);
  };

  const handlePreviousPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + selectedPhotos.length) % selectedPhotos.length);
  };

  const handleDeletePhoto = () => {
    const newPhotos = selectedPhotos.filter((_, index) => index !== currentPhotoIndex);
    const newPhotosRaw = selectedPhotosRaw.filter((_, index) => index !== currentPhotoIndex);
    setSelectedPhotos(newPhotos);
    setSelectedPhotosRaw(newPhotosRaw);
    setCurrentPhotoIndex((prevIndex) => (newPhotos.length > 0 ? 0 : prevIndex));
    if (newPhotos.length === 0) {
      setUploadStep("initial")
    }
  };
  console.log(selectedPhotos, "selectedPhotosRaw", selectedPhotosRaw)
  const handleShare = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      selectedPhotosRaw.forEach((photo) => {
        formData.append("images", photo);
      });
      formData.append("caption", caption);
      console.log(formData)
      const response = await axiosConfig.post("accounts/model-images/create_with_images/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Post successful!", response.data);
      setAddPost(false)
      setpopupType("success")
      setLoading(false)
    }
    catch (error) {
      alert("Error while upload photo, please upload another photo")
      console.error("Error creating post:", error);
      setLoading(false)
    }
  };
  return (
    <>
      {/* <Topbar /> */}
      {/* <CustomTop />
      <div className="main-container">
        <Navbar />
        <div className="content-area">
          <div className="container mt-4">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Create new post</h5>
                  </div>
                  <div className="card-body text-center">

                    {uploadStep === "initial" ? (
                      <div className="mb-3">
                        <MdPhotoLibrary size={48} />
                        <p>Drag photos and videos here</p>
                        <label className="btn btn-primary">
                          Select photos from computer
                          <input type="file" accept="image/*" multiple onChange={handlePhotoUpload} hidden />
                        </label>
                      </div>
                    ) : (
                      <div className="mb-3">
                        <div className="arrowicon">
                          <MdArrowBack size={36} className="flleft" onClick={() => setUploadStep("initial")} />
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            {selectedPhotos.length > 0 && (
                              <div className="mb-3">
                                <img src={selectedPhotos[currentPhotoIndex]} alt="Uploaded" style={{ maxWidth: "100%" }} />
                                {selectedPhotos.length > 1 && (
                                  <div>
                                    <button className="btn btn-link" onClick={handlePreviousPhoto}>
                                      Previous
                                    </button>
                                    <button className="btn btn-link" onClick={handleNextPhoto}>
                                      Next
                                    </button>
                                  </div>
                                )}
                                <button className="btn btn-danger" onClick={handleDeletePhoto}>
                                  Delete
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            {selectedPhotos.length > 0 && (
                              <textarea
                                className="form-control mb-3"
                                placeholder="Write caption..."
                                value={caption}
                                onChange={(e) => setCaption(e.target.value)}
                              />
                            )}
                            {selectedPhotos.length > 0 && (
                              <> 
                               {loading ?
                                <button className="btn btn-primary btn-block " disabled>Uploading Started...</button>
                                :
                                  <button className="btn btn-primary btn-block" onClick={handleShare}>Share</button>
                                }
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {popupType != "" &&
            <PopupModal popupType={popupType} Navigation="/" />
          }
        </div>
        <div className="right-area">
        </div>
      </div>
      <Footer /> */}
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={AddPost} onHide={() => setAddPost(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="container">
              <div className="row justify-content-center">
                <div
                // className="col-md-9"
                >
                  <div className="">
                    {/* <div className="card-header">
                      <h5 className="card-title">Create new post</h5>
                    </div> */}
                    <div className="card-body text-center">
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-12">
                              <FabricImageEditor setCaption={setCaption} caption={caption} setLoading={setLoading} loading={loading} setpopupType={setpopupType} setAddPost={setAddPost} popupType={popupType}/>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { NewPost }
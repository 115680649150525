const sidebarData = {
    "accounts" : [
        {
            "SubTitle":"Accounts",
            "NavItems":[
                {"label":"users","path":"/dashboard/users","icon":require("./images/users.png")},
                {"label":"User Module Permission","path":"/dashboard/users","icon":require("./images/chat.png")},
                {"label":"radiologist","path":"/dashboard/radiologist","icon":require("./images/Radiologist.png")}
            ]
        },
        {
            "SubTitle":"Clients",
            "NavItems":[
                {"label":"clients","path":"/dashboard/users/clients","icon":require("./images/Clients.png")},
            ]
        }
    ],
    "masters": [
        {
            "SubTitle":"References",
            "NavItems":[
                {"label":"Domain","path":"/dashboard/masters/domains","icon":require("./images/domain.png")},
                {"label":"ReferenceDomain Value","path":"/dashboard/masters/domains/value","icon":require("./images/reference domain.png")}
            ]
        },
        {
            "SubTitle":"Locations",
            "NavItems":[
                {"label":"Country","path":"/dashboard/masters/countries","icon":require("./images/country.png")},
                {"label":"State","path":"/dashboard/masters/states","icon":require("./images/tstate.png")},
                {"label":"City","path":"/dashboard/masters/cities","icon":require("./images/city.png")},
                {"label":"Area","path":"/dashboard/masters/areas","icon":require("./images/area.png")}
            ]
        },
        {
            "SubTitle":"Configurations",
            "NavItems":[
                {"label":"Department","path":"/dashboard/masters/departments","icon":require("./images/department.png")},
                {"label":"Sub Department","path":"/dashboard/masters/sub-departments","icon":require("./images/tariff.png")},
                {"label":"Services","path":"/dashboard/masters/services","icon":require("./images/lead.png")},
                {"label":"Bill Head","path":"/dashboard/masters/billhead","icon":require("./images/lead.png")},
                {"label":"Priority","path":"/dashboard/masters/priority","icon":require("./images/lead.png")},
                {"label":"TariffType","path":"/dashboard/masters/tarifftype","icon":require("./images/lead.png")},
                {"label":"Tariff Card","path":"/dashboard/masters/tariffcard","icon":require("./images/lead.png")},
                {"label":"Module","path":"/dashboard/masters/module","icon":require("./images/module.png")},
                {"label":"Module Screen","path":"/dashboard/masters/modulescreen","icon":require("./images/chat.png")},
                {"label":"Module Permission","path":"/dashboard/masters/modulepermission","icon":require("./images/chat.png")},
                {"label":"Role","path":"/dashboard/masters/role","icon":require("./images/role.png")},
                {"label":"Role Module Permission","path":"/dashboard/masters/rolemodulepermission","icon":require("./images/role module.png")}
            ]
        },
        
        
    ],
    "billing": [
        {
            "SubTitle":"Billing",
            "NavItems":[
                {"label":"Case Upload","path":"/dashboard/billing/upload-cases","icon":require("./images/upload-cases.png")},
                {"label":"Client Build Services","path":"/dashboard/billing/client-billed-services","icon":require("./images/client-billed-services.png")},
                {"label":"Invoices","path":"/dashboard/billing/invoices","icon":require("./images/invoices.png")}
            ]
        }
    ],
    "worklists": [
        {
            "SubTitle":"Work List",
            "NavItems":[
                {"label":"My Work List","path":"#","icon":require("./images/upload-cases.png")}
            ]
        },
        {
            "SubTitle":"Tickets",
            "NavItems":[
                {"label":"Tickets","path":"#","icon":require("./images/upload-cases.png")}
            ]
        }
    ],
    "finance": [
        {
            "SubTitle":"Finance",
            "NavItems":[
                {"label":"Finance","path":"#","icon":require("./images/upload-cases.png")}
            ]
        }
    ],
    "reports": [
        {
            "SubTitle":"Reports",
            "NavItems":[
                {"label":"Reports","path":"#","icon":require("./images/upload-cases.png")}
            ]
        }
    ],
    "assets": [
        {
            "SubTitle":"Assets",
            "NavItems":[
                {"label":"Assets","path":"#","icon":require("./images/upload-cases.png")}
            ]
        }
    ],
}
export default sidebarData;
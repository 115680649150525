import React, { useEffect, useState } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { MobileNavBar } from "../Components/Navbar";
import { NavLink,useNavigate } from "react-router-dom";
import {MdOutlineEditLocation} from "react-icons/md"
import {AiOutlineReconciliation,AiOutlineUser} from "react-icons/ai"
import {BiRupee} from "react-icons/bi"
import axiosConfig from "../Service/axiosConfig";
const Wallet = () => {
    const[ActiveUrl,setActiveUrl]=useState("")
    const[wallet,setWallet]=useState({})
    const[Loading,setLoading]=useState()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    let Navigate=useNavigate();
  const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
  };
    const ActiveData=(activeUrl)=>{
        localStorage.setItem("activeurl",activeUrl)
    }
    const GetlocaData=()=>{
        const Accounturl=localStorage.getItem("activeurl")
        setActiveUrl(Accounturl)
    }
    const GetData = async () => {
        setLoading(true)
        try {
          const response = await axiosConfig.get(`/accounts/wallet/?user=${localStorage.getItem("userId")}`)
          setWallet(response.data.results)
          setLoading(false)
        }
        catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
      console.log(wallet)
      const HandleNavigate=()=>{
        Navigate("/account")
      }
    useEffect(()=>{
        GetData()
        GetlocaData()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[])
    return (
        <>
            {isMobile?<MobileNavBar HandleNavigate={HandleNavigate}/>:<Navbar />}
            {
              isMobile?
              <div className="container my-wallet">
              <div className="row">
              <div className="col-12  my-profile__right_rn">
              <div class="user-wallet">
               <div class="user-wallet__balance">My Balance:
               <span class="user-wallet__amount">₹{wallet.wallet_amount?wallet.wallet_amount:0}</span></div>
               </div>
              </div>
              </div>
              </div>:
               <div className="container my-profile_rn">
               <div className="row">
               <div className="col-lg-2 col-md-2 profile-main">
                <div className="profile-nav__details-block">
                <div class="account-profile__img"></div>
                <div class="account-profile__phone">{localStorage.getItem("number")}</div>
                </div>
                <nav className="profile-nav__list">
                  <ul className="list-unstyled">
                     <NavLink to="/account/addresses" className={ActiveUrl==="/account/addresses"?"profile-nav__list-item-active":"profile-nav__list-item"} onClick={()=>ActiveData("/account/addresses")}><li  className="item-text"><span className="mx-2"><MdOutlineEditLocation/></span>My Addresses</li></NavLink>
                     <NavLink to="/account/orders" className={ActiveUrl==="/account/orders"?"profile-nav__list-item-active":"profile-nav__list-item"} onClick={()=>ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation/></span>My Orders</li></NavLink>
                     <NavLink to="/account/wallet" className={ActiveUrl==="/account/wallet"?"profile-nav__list-item-active":"profile-nav__list-item"} onClick={()=>ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee/></span>My wallet</li></NavLink>
                     <NavLink to="/logout" className={ActiveUrl==="/account/addresses"?"profile-nav__list-item-active":"profile-nav__list-item"} onClick={()=>ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser/></span>Logout</li></NavLink>
                  </ul>
                </nav>
               </div>
               <div className="col-lg-10 col-md-10 my-profile__right_rn">
               <div class="user-wallet">
                <div class="user-wallet__balance">My Balance:
                <span class="user-wallet__amount">₹{wallet.wallet_amount?wallet.wallet_amount:0}</span></div>
                </div>
               </div>
               </div>
               </div>
            }
        </>
    )
}
export { Wallet }
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import axiosConfig from "../Service/axiosConfig";
import { Follow } from "../Components";
import { useNavigate } from "react-router-dom";

const Notifications = (props) => {
    const {
        show,
        handleClose,
        ProfileData,
        setIsRead,
        isRead,
        page,
        setPage,
    } = props;
    const [Data, setData] = useState([]);
    const [count, setCount] = useState(0)
    const GetData = async () => {
        console.log(page)
        try {
            const response = await axiosConfig.get(`/accounts/notifications/?page_size=10&page=${page}&profile=${ProfileData?.profile_id}&is_read=`);
            setCount(response.data.count)
            if (page === 1) {
                setData(response.data.results);
            } else {
                setData([...Data, ...response.data.results])
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const handleScroll = (e) => {
        const { clientHeight, scrollTop, scrollHeight } = e.target
        const tolerance = 5;
        if (clientHeight + scrollTop >= scrollHeight - tolerance && Math.ceil(count / 10) > page) {
            setPage(page + 1)
        }
    }
    useEffect(() => {
        if (show) {
            GetData();
        }
    }, [show, page]);

    const Navigate = useNavigate();
    const handleNavigate = (link) => {
        Navigate(`/profile/${link}`);
    }
    const getMessage = (type) => {
        switch (type) {
            case "follow":
                return "started following you";
            case "applied":
                return "applied for your audition";
            case "like":
                return "liked your post";
            case "shortlist":
                return "shortlisted you";
            default:
                return "";
        }
    };
    const calculateTime = (endDate) => {
        const end = new Date(endDate);
        const now = new Date();
        const difference = now - end;
        const units = [
            { label: 'd', value: 24 * 60 * 60 * 1000 },
            { label: 'h', value: 60 * 60 * 1000 },
            { label: 'm', value: 60 * 1000 },
            { label: 's', value: 1000 }
        ];
        for (const unit of units) {
            const diffInUnit = Math.floor(difference / unit.value);
            if (diffInUnit > 0) {
                return `${diffInUnit}${unit.label}`;
            }
        }
        return 'just now';
    };
    return (
        <>
            <Modal show={show} onHide={handleClose} className="popup-cart">
                <Modal.Header closeButton>
                    <Modal.Title>Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body className="cart-modal-address">
                    <div className="py-2 bill-details">
                        {/* <div className="cart-timer">
                            <div className={`notification-header ${!isRead ? 'active' : ''}`} onClick={() => setIsRead(false)}>
                                Unread
                            </div>
                            <div className={`notification-header ${isRead ? 'active' : ''}`} onClick={() => { setIsRead(true); setPage(1) }}>
                                Read
                            </div>
                        </div> */}
                        <div className=" cart-modal-addresses" onScroll={(e) => handleScroll(e)}>
                            {Data && Data.map((item) => (
                                <div className="productItem" key={item.id}>
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-3">
                                            <img src={item?.from_user?.profile_pic ? item?.from_user?.profile_pic : require('../Components/Post/images/male.jpg')} className="cart-display-imgs" alt={item?.from_user?.first_name} />
                                        </div>
                                        <div className="col-6">
                                            {item?.from_user && <div onClick={() => handleNavigate(item?.from_user?.hcc_id)}><b>{item?.from_user?.first_name}</b> {getMessage(item?.notification_type)}. <span className="notifi-time">{item.created_date && calculateTime(item.created_date)}</span>
                                            </div>}
                                        </div>
                                        <div className="col-3">
                                            {item.notification_type === "like" ? (
                                                <img src={item?.model_image?.image} className="cart-display-img" alt="Model" />
                                            ) : (
                                                <Follow FollowId={item?.from_user?.id} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export { Notifications };

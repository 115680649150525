import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal"
import { DeletePopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig"
import { MdMoreVert, MdOutlineEdit, MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineTouchApp, MdOutlineWatchLater } from "react-icons/md";
import { useAuth } from '../Utils/AuthContext';
import { GrAlarm } from "react-icons/gr";

const ProfileAudition = ({ ProfileData }) => {
    const Navigate = useNavigate();
    const { isLoggedIn, is24Craft } = useAuth();
    const profile_data = JSON.parse(localStorage.getItem('profile_data'))?.profile_id;
    const frames_24 = localStorage.getItem('frames_24');
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [AudiData, setAudiData] = useState([]);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const handleNavigate = (id, type) => {
        console.log(type)
        if (type === "view") {
            Navigate(`/auditions/${id}`)
        } else if (type === "add") {
            Navigate(`/auditions/add`)
        } else {
            Navigate(`/auditions/${id}/edit`)
        }
    }
    const GetData = async (status, profile) => {
        try {
            const response = await axiosConfig.get(`/accounts/auditions/?status=${status}&profile=${ProfileData ? ProfileData.id : profile}`);
            setAudiData(response.data.results);
        } catch (error) {
            console.log(error);
        }
    }
    function getProjectType(status) {
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    }
    function getProjectTypeColor(status) {
        switch (status) {
            case 'Movies':
                return '#8b008b';
            case 'OTT':
                return '#696969';
            case 'TV_Serials':
                return '#8b4513';
            case 'Web_Series':
                return '#4682b4';
            case 'Music_Albums':
                return '#bdb76b';
            case 'Cover_Songs':
                return '#a52a2a';
            case 'Item_Song':
                return '#c71585';
            case 'Short_Films':
                return '#228b22';
            case 'Ads':
                return '#d2691e';
            case 'Modelling':
                return '#20b2aa';
            case 'Audition':
                return '#db7093';
            default:
                return '#ffffff';
        }
    }
    const shareContent = async (share_to, data) => {
        try {
            let baseUrl = `${window.location.protocol}//${window.location.host}`;
            let URL = `${baseUrl}/auditions/${data.id}/`;
            let text = `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""}  ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${URL}`
            const shareUrl = encodeURIComponent(text);
            console.log(shareUrl);
            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Audition',
                        text: `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""}  ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${URL}`
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const copyToClipboard = async (data) => {
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        let shareUrl = `${baseUrl}/auditions/${data.id}/`;
        try {
            await navigator.clipboard.writeText(
                `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""} ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${shareUrl} `
            );
            console.log('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")

    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = (id) => {
        console.log(id)
        axiosConfig.delete(`/accounts/auditions/${id}/`).then(res => {
            console.log(res)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
            GetData()
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        GetData()
    }, [])
    function timeAgo(dateString) {
        const now = new Date();
        const pastDate = new Date(dateString);
        const secondsAgo = Math.floor((now - pastDate) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);

        if (daysAgo > 0) return `${daysAgo}d ago`;
        if (hoursAgo > 0) return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
        if (minutesAgo > 0) return `${minutesAgo}min${minutesAgo > 1 ? 's' : ''} ago`;
        return `${secondsAgo}s`;
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    return (
        <>
            <div className="row justify-content-between">
                <div className="col-md-7">
                    {/* <h6 className="opp-title">Opportunities</h6>
                    <p className="opp-quote">Continue your star journey</p> */}
                </div>
                <div className="col-md-5">
                    <div className="btnflx">
                        {frames_24?.split(',')?.some(role =>
                            ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director"].includes(role.trim())
                        ) && isLoggedIn && (ProfileData ? ProfileData.id === profile_data : profile_data) && (
                                <div className="btnitem">
                                    <button className="btn btn-primary" onClick={() => handleNavigate("", "add")}>+ Add New</button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <div className="row" style={{ paddingLeft: "15px" }}>
                {AudiData.map((Audition, index) => (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={index} >
                        <div className="card card-style" style={{ backgroundColor: getProjectType(Audition.project_type) }}>
                            <div className="card-type card-types" style={{ backgroundColor: getProjectTypeColor(Audition.project_type) }}>{Audition.project_type ? Audition.project_type : "---"}</div>
                            <div className="dropdown audi-edit">
                                <MdOutlineMoreVert data-bs-toggle="dropdown" size={25} />
                                <ul className="dropdown-menu">
                                    {Audition?.profile?.id === JSON.parse(localStorage.getItem('profile_data'))?.profile_id &&
                                        <>
                                            <li><a className="dropdown-item" onClick={() => handleNavigate(Audition.id, "edit")}>edit</a></li>
                                            <li><a className="dropdown-item" onClick={() => DeleteAlert(Audition.id)}>Delete</a></li>
                                        </>
                                    }
                                    <li><a className="dropdown-item" onClick={() => copyToClipboard(Audition)}>Copy URL</a></li>
                                    <li><a className="dropdown-item" onClick={() => shareContent("share", Audition)}>Share</a></li>
                                </ul>
                            </div>
                            <div className='card-content'>
                                {Audition.banner_name ? (
                                    <h4 className="banner-title">
                                        {Audition.banner_name}
                                    </h4>
                                ) : (
                                    <div style={{ padding: '20px' }}></div>
                                )}

                                <div className="card-descrt">
                                    <div onClick={() => handleNavigate(Audition.id, "view")} className="card-descrt-inner" style={{ height: "320px" }}>
                                        <div className="project_title">
                                            <div className="audi-id">AD{Audition.id && Audition.id}</div>
                                            <div>
                                                <div className='Audi-project-title'>{Audition.project_title && Audition.project_title}</div>
                                                <div className='Audi-description'>{Audition.description && Audition.description}</div>
                                            </div>
                                        </div>
                                        <div className="card-audi">
                                            {Audition.platform &&
                                                <div className="audi-label" style={{ background: '#B10DE8' }}>{Audition.platform}</div>
                                            }
                                            {Audition.zoner &&
                                                <div className="audi-label" style={{ background: '#67163F' }}>{Audition.zoner}</div>
                                            }
                                            {Audition.loc_lang.city &&
                                                <div className="audi-label" style={{ background: '#3CB371' }}>{Audition.loc_lang.city}</div>
                                            }
                                            {Audition.loc_lang.languages &&
                                                <div className="audi-label" style={{ background: '#DC7092' }}>{Audition.loc_lang.languages}</div>
                                            }
                                        </div>
                                        <div className="card-detail">
                                            <div className={`card-botton-centent ${Audition.hero ? 'align-items-left' : 'align-left-right'}`}>
                                                <div className="card-cont-title">Producer</div>
                                                <div className='card-cont-detail'>{Audition.producer_name ? Audition.producer_name : "-"}</div>
                                            </div>
                                            <div className={`card-botton-centent ${Audition.hero ? 'align-items-center' : 'align-items-end'}`}>
                                                <div className="card-cont-title">Director</div>
                                                <div className='card-cont-detail'>{Audition.director_name ? Audition.director_name : "-"}</div>
                                            </div>
                                            {Audition.hero && <div className="card-botton-centent align-items-end">
                                                <div className="card-cont-title">Hero</div>
                                                <div className='card-cont-detail'>{Audition.hero ? Audition.hero : "-"}</div>
                                            </div>}
                                        </div>
                                        <div className="auditionList-require">
                                            <div className="card-cont-title">Description :</div>
                                            <div className='card-cont-detail'>
                                                {Audition.generated_requirement && Audition.generated_requirement.trim() !== "" ? Audition.generated_requirement : "No requirements available"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-bottom">
                                        <div className="card-bottom-lefts justify-content-around">
                                            <div className="card-bottom-detail"><GrAlarm size={14} /><span style={{ marginLeft: "5px" }}>{timeAgo(Audition?.created_at)}</span></div>
                                            <div className="card-bottom-detail"><MdOutlineRemoveRedEye size={14} /><span style={{ marginLeft: "5px" }}>{Audition?.views}</span></div>
                                            {/* <div className="card-bottom-detail" style={{ color: "#212529" }}>Applications <span style={{ marginLeft: "5px" }}> ({Audition?.applications})</span></div> */}
                                        </div>
                                        <div className="view-button-profile">
                                            <button type="button" className="btn btn-primary btn-block" onClick={() => handleNavigate(Audition.id, "view")}>View More</button>
                                            {/* <button type="button" className="btn btn-secondary" onClick={() => shareContent("share", Audition.id)}>Share</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                ))}
            </div>
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Delete Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
        </>
    )
}
export { ProfileAudition };
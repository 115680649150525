import React from "react";
import { DashboardLayout, Navbar,ProductDetail,ProductsList,SubCategories,Footer } from "../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink,useParams } from "react-router-dom";
import { BreadCrumbs } from "../Components/BreadCrums";
import "./Pages.css"
const ProductDetailPage = (props) => {
    const {product_data}=props;
   console.log(props)

    return (
        <>
            <Navbar />
            <div className="breadcrumbs-product-detail">
                {
                 (props.categoryurl&&props.subcategoryurl)?<div className="">
                 <BreadCrumbs
                     active_page={props.friendlyurl}
                     prev_sub_page={{
                         'link':props.subcategoryurl,
                         'text':props.subcategoryurl
                     }}
                     sub_page={{
                        'link':props.categoryurl,
                        'text':props.categoryurl
                    }}
                 />
                 </div>:
                 <BreadCrumbs
                 active_page={props.friendlyurl}
             />
                }
                    
                    </div>
            <div className="container">
                <ProductDetail item={product_data} />
            </div>
            <Footer />
        </>
    )

}
export {ProductDetailPage}
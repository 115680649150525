import React from "react";
import CustomTop from "./CustomTop";
import { Footer, Navbar } from "../Components";
import { MdMail, MdOutlinePhoneInTalk, MdPinDrop } from "react-icons/md";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const ContactUs = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ',
        googleMapsApiKey: "AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ"
    });

    // Container style for the map
    const containerStyle = {
        width: '100%',
        height: '400px'  // Set explicit height for the map container
    };

    const center = {
        lat: 17.4297324,
        lng: 78.4475088
    };

    return (
        <>
            <CustomTop />
            <div className='main-container'>
                <Navbar />
                <div className="container privacy-policy">
                    {/* <h1 style={{ textAlign: 'center' }}>Contact Details</h1> */}
                    <div className="inner-container">
                        <section className="contact" id="contact">
                            <div className="container">
                                <h2>Contact Details</h2>
                                {/* <p className="contact-quote">Do You Have A Project That You Would Like To Discuss On? Talk To Our Expert</p> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="email">
                                                    <MdMail size={40} color={"#0d53ad"} />
                                                    <h3>Email</h3>
                                                    <p><a href="mailto:info@hyderbadcastingclub.com" className="anchor-text">info@hyderbadcastingclub.com</a></p>
                                                    <br />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="phone">
                                                    <MdOutlinePhoneInTalk size={40} color={"#0d53ad"} />
                                                    <h3>Call</h3>
                                                    <p><a href={`tel:+91 7799234563`} className="anchor-text">+91 7799234563</a></p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="address mb-5" style={{ padding: '24px', marginTop: "20px" }}>
                                            <MdPinDrop size={40} color={"#0d53ad"} />
                                            <h3>Address</h3>
                                            <p>Address1 : Srinagar Colony Main Rd, Pratap Nagar, VST Colony, Punjagutta, Hyderabad, Telangana 500082</p>
                                            <p>Address2 : Plot No. 658, 3rd Floor, Ayyappa Society, Madhapur, Telangana 500081</p>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="box-with-shadow">
                                            <h4 className="hide-mobile">Our Location</h4>
                                            {isLoaded &&
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={center}
                                                    zoom={10}
                                                >
                                                    <Marker position={center} />
                                                </GoogleMap>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export { ContactUs };
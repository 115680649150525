import React, { useState, useEffect } from "react";
import "./FriendlyUrlComponent.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink,useParams } from "react-router-dom";
import { CategoriesPage,SubCategoriesPage,ProductDetailPage } from "../../Pages";

const FriendlyUrlComponent = () => {

    let { subcategoryurl,categoryurl,friendlyurl } = useParams();
    console.log(friendlyurl)
    console.log(categoryurl)
    console.log(subcategoryurl)

    const[loading,setLoading]=useState(false)
    const[ProductObject,setProductObject]=useState({
        "product_type" : "",
        "product_data" : "",
    })
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/catlog/seo-url/${friendlyurl}/`)
            console.log(response)
            setProductObject({"product_type":response.data.product_type,"product_data":response.data.product_data})
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData();
    }, [friendlyurl])

    return (
        <>
           <div className="categories-container">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                <div className="">
                    {ProductObject.product_type == "category" &&
                        <CategoriesPage category_id={ProductObject.product_data.id} category_slug={friendlyurl}/>
                    }
                    {ProductObject.product_type == "subcategory" &&
                        <SubCategoriesPage subcategory_id={ProductObject.product_data.id} category_id={ProductObject.product_data.category} friendlyurl={friendlyurl}/>
                    }
                    {ProductObject.product_type == "product" &&
                        <ProductDetailPage product_data={ProductObject.product_data} friendlyurl={friendlyurl} categoryurl={categoryurl} subcategoryurl={subcategoryurl}/>
                    }
                </div>
                )}
           </div>
        </>
    )
}
export { FriendlyUrlComponent };
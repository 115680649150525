import React, { useState, useEffect } from "react";
import "./Products.css"
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { addProduct, removeProduct } from "../../redux/Slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { MdAdd, MdRemove } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { BreadCrumbs } from "../BreadCrums";

const ProductDetail = (props) => {
    const { cart } = useSelector((state) => state);
    const dispatch = useDispatch();

    const addToCart = () => {
        dispatch(addProduct({ product: { id: item.id, name: item.name, image: item.image, price: item.price }, quantity: 1 }));
        console.log("added")
    };

    const removeFromCart = () => {
        dispatch(removeProduct({ productId: item.id, quantity: 1 }));
        console.log("Item removed from your cart")
    };

    const { item } = props;
    
    const getProductQuantity = (productId) => {
        const product = cart.products.find((p) => p.id === productId);
        return product ? product.quantity : 0;
    };


    return (
        <div className="product-detail">
            <div className="row">
                <div className="col-md-6">
                    <div className="product-detail-img">
                        <img src={item.image} />
                    </div>
                </div>
                <div className="col-md-6 product-detail-info">
                    <p className="p-title">{item.name}</p>
                    <hr />
                    <div className="product-detail-actions">
                        <div className="row">
                            <div className="col-6">
                                <div className="p-price">
                                    <p>ProductModel:</p>
                                    {(item.weight_in_kg && item.weight_in_kg > 0)?(<p>weight_in_kg: {item.weight_in_kg}</p>):(<></>)}
                                    <p>max_qty: {item.max_qty}</p>
                                    {item.brand?(<p>Brand:{item.brand}</p>):(<></>)}
                                    <div className="d-flex">
                                    {item.offer_price && item.offer_price>0 ?(
                                        <>
                                            <p className={item.offer_price ? "produc-price" : "price"}>₹{item.price}</p>
                                            <p className="price ms-2">₹{item.offer_price}</p>
                                            <span className="offer-price ms-2">{item.offer_price?`${((item.price - item.offer_price)/item.price * 100).toFixed(1)}% off`: " "}</span>
                                        </>
                                        ):(
                                            <p className="price">₹{item.price}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="product-actions">

                                    <div className="p-btn">
                                        {cart.products.some((p) => p.id === item.id) ? (
                                            <div className="btn btn-cart btn-cart-added">
                                                <button className="btn-minus" onClick={removeFromCart}>
                                                    <MdRemove />
                                                </button>
                                                <div className="qty">{getProductQuantity(item.id)}</div>
                                                <button className="btn-minus" disabled={item.max_qty === getProductQuantity(item.id)} onClick={addToCart}>
                                                    <MdAdd />
                                                </button>
                                            </div>
                                        ) : (
                                            <button className="btn btn-cart" onClick={addToCart}>Add</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-description">
                <p className="desc-title">Product Details</p>
                <p dangerouslySetInnerHTML={{ __html: item.description }} />


            </div>

        </div>
    )
}
export { ProductDetail };
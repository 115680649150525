import React, { useEffect, useState } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";
import { Follow } from './Follow';
import { FollowingPop } from "../../Components/Follow"
const FollowersPop = ({ ProfileData, Following, setFollowing, Follower, setFollower }) => {
    console.log(ProfileData, "ProfileData", Follower)
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const [Likes, setLikes] = useState([]);
    const GetLikes = async () => {
        try {
            console.log(ProfileData.id)
            const response = await axiosConfig.get(`/accounts/follows/?profile=${ProfileData.id}&page_size=100`)
            console.log(response)
            setLikes(response.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        GetLikes();
    }, [])
    return (
        <>
            < Modal
                show={Follower}
                onHide={() => setFollower(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
                style={{
                    paddingLeft: "0px",
                    height: "100%",  // Set the modal to 100% height
                }}
                dialogClassName="modal-100h"
            >
                <Modal.Header closeButton>
                    <Modal.Title className='hide-mobile'>
                        Followers
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="posting-container desktop-mode">
                        {Likes.map((like) => {
                            return (
                                <>
                                    <div className='like-content'>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <NavLink target="_blank" to={`/profile/${like.user.hcc_id}`}>
                                                <img
                                                    src={like.user.profile_pic ? like.user.profile_pic : require('../Post/images/male.jpg')}
                                                    alt={`${like.user?.first_name} ${like.user?.last_name}`} className='profile-like'
                                                />
                                            </NavLink>
                                            <div>
                                                <NavLink target="_blank" to={`/profile/${like.user.hcc_id}`} className="like-name">
                                                    <span>{like.user ? `${like.user.first_name}` : ''}</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        {/* <button className='btn btn-primary' style={{ borderRadius: '8px' }} ><Follow FollowId={like.user.id} /></button> */}
                                        <Follow FollowId={like.user.id} />
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <>
                        <div className="profile-tablinks responsive">
                            <ul className="nav custom-nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        Followers
                                    </button>
                                </li>
                                {profile_data?.profile_id === ProfileData.id &&
                                    <li className="nav-item">
                                        <button
                                            className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                            onClick={() => handleTabClick(1)}
                                        >
                                            Following
                                        </button>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="tab-content desktop-mode">
                            <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}>
                                <div className="posting-container">
                                    {Likes.map((like) => {
                                        return (
                                            <>
                                                <div className='like-content'>
                                                    <div className='d-flex' style={{ gap: '10px' }}>
                                                        <NavLink target="_blank" to={`/profile/${like.user.hcc_id}`}>
                                                            <img
                                                                src={like.user.profile_pic ? like.user.profile_pic : require('../Post/images/male.jpg')}
                                                                alt={`${like.user?.first_name} ${like.user?.last_name}`} className='profile-like'
                                                            />
                                                        </NavLink>
                                                        <div>
                                                            <NavLink target="_blank" to={`/profile/${like.user.hcc_id}`} className="like-name">
                                                                <span>{like.user ? `${like.user.first_name}` : ''}</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                    {/* <button className='btn btn-primary' style={{ borderRadius: '8px' }} ><Follow FollowId={like.user.id} /></button> */}
                                                    <Follow FollowId={like.user.id} />
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}>
                                {activeTab === 1 &&
                                    <div className="display-bio">
                                        <FollowingPop Following={false} setFollowing={setFollowing} ProfileData={ProfileData} />
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal >
        </>
    );
};

export { FollowersPop };

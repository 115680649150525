import React from 'react';
import { Footer, Navbar } from '../Components';
import CustomTop from './CustomTop';

const RefundPolicy = () => {
    return (
        <>
            <CustomTop />
            <div className='main-container'>
                <Navbar />
                <div className="container privacy-policy">
                    <h1 style={{ textAlign: 'center' }}>Refund Policy</h1>
                    <p><strong>Effective Date:</strong> 10th August 2024</p>
                    <h4>1. Introduction</h4>
                    <p>
                        This Refund Policy explains the conditions under which MHN Informatics
                        Private Limited ("we," "us," "our") offers refunds for purchases made on
                        our platform, Hyderabad Casting Club World (
                        <a href="https://www.hyderabadcastingclub.com" target="_blank" rel="noopener noreferrer">
                            www.hyderabadcastingclub.com
                        </a>
                        ).
                    </p>
                    <h4>2. Pro/Premium Subscription Refunds</h4>
                    <p><strong>2.1. Eligibility for Refunds</strong></p>
                    <ul>
                        <li>
                            <strong>7-Day Refund Period:</strong> We offer a 7-day refund period
                            for Pro/Premium subscriptions from the date of purchase. If you are not
                            satisfied with our Pro/Premium services, you may request a refund within
                            this period.
                        </li>
                        <li>
                            <strong>Non-Eligibility:</strong> Refunds are not available after the
                            7-day period has expired.
                        </li>
                    </ul>
                    <p><strong>2.2. Refund Process</strong></p>
                    <ul>
                        <li>
                            <strong>Requesting a Refund:</strong> To request a refund, please
                            contact our customer support team at <b>info@hyderabadcastingclub.com</b> within 7
                            days of your purchase. Provide your account details and reason for
                            requesting the refund.
                        </li>
                        <li>
                            <strong>Processing Time:</strong> Refund requests are typically
                            processed within 7-10 business days from the date of receipt. The refund
                            will be issued to the original payment method used during the purchase.
                        </li>
                    </ul>
                    <h4>3. Non-Refundable Items</h4>
                    <ul>
                        <li>
                            <strong>Ineligible Refunds:</strong> Certain services and features may
                            not be eligible for refunds, including but not limited to advertising
                            credits, promotional purchases, or services provided outside of the
                            Pro/Premium subscription.
                        </li>
                        <li>
                            <strong>Digital Goods:</strong> Once digital goods or services have been
                            accessed or downloaded, they are not eligible for a refund.
                        </li>
                    </ul>
                    <h4>4. Changes to Subscriptions</h4>
                    <ul>
                        <li>
                            <strong>Cancellation:</strong> If you wish to cancel your Pro/Premium
                            subscription, you may do so at any time through your account settings.
                            No refunds will be issued for partial subscription periods or unused
                            portions of the subscription.
                        </li>
                        <li>
                            <strong>Refunds for Cancellations:</strong> If you cancel within the
                            7-day refund period, you may be eligible for a refund as outlined in
                            section 2.
                        </li>
                    </ul>
                    <h4>5. Contact Us</h4>
                    <p>
                        If you have any questions or need assistance with the refund process,
                        please contact our customer support team:
                    </p>
                    <p>
                        <strong>Email:</strong> info@hyderabadcastingclub.com
                        <br />
                        <strong>Address:</strong> H.NO-8-2-1/b/1, Sri Nagar Colony, Hyderabad, Telangana – 500082
                    </p>
                    <h4>6. Amendments</h4>
                    <p>
                        We reserve the right to amend or update this Refund Policy at any time.
                        Any changes will be posted on our website, and the updated policy will
                        be effective immediately upon posting.
                    </p>
                    <p>
                        By using our services, you agree to the terms of this Refund Policy.
                        Please review it periodically for any changes.
                    </p>
                </div >
            </div >
            <Footer />
        </>

    );
};

export { RefundPolicy };

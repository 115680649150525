import React, { useEffect, useState } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { NavLink, useParams,useNavigate } from "react-router-dom";
import { MdOutlineEditLocation } from "react-icons/md"
import { AiOutlineReconciliation, AiOutlineUser, AiOutlineArrowLeft } from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig";
import { BiRupee } from "react-icons/bi";
import "./MyOrderDetails.css";
import { current } from "@reduxjs/toolkit";

const MyOrderDetails = () => {
  const [loading, setLoading] = useState()
  const [Orders, setOrders] = useState([])
  const [OrdersData, setOrdersData] = useState({})
  const [Address, setAddress] = useState({})
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  let Navigate=useNavigate()
  const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
  };
  const [OrderDate, setOrderDate] = useState({
    dayOfWeek: "",
    dayOfMonth: "",
    month: "",
    year: "",
    hours: "",
    minutes: "",
    ampm:""
  })
  const [OrderMRP, setOrderMRP] = useState({
    mrp: 0,
    discount: 0,
    totalAmount: 0,
    percentage: 0
  })
  const [ActiveUrl, setActiveUrl] = useState("")
  let { orderId, address } = useParams()
  const ActiveData = (activeUrl) => {
    localStorage.setItem("activeurl", activeUrl)
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetlocaData = () => {
    const Accounturl = localStorage.getItem("activeurl")
    setActiveUrl(Accounturl)
  }
  const GetData = async () => {
    setLoading(true)
    try {
      const response = await axiosConfig.get(`/accounts/order-details/?order=${orderId}`)
      setOrders(response.data.results)
      ProductMRP(response.data.results)
      console.log(response.data.results)
      // GetEditdata()
      setLoading(false)

    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const GetOrders = async () => {
    const response = await axiosConfig.get(`/accounts/orders/${orderId}/`)
    console.log(response)
    setOrdersData(response.data)
    const dateString = response.data.order_date

    const date = new Date(dateString);

    console.log(date)
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const dayOfWeek = weekdays[date.getUTCDay()];
    const dayOfMonth = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hour1=hours%12
    setOrderDate({
      dayOfWeek:dayOfWeek,
      dayOfMonth:dayOfMonth,
      month:month,
      year:year,
      hours:hour1,
      minutes:minutes,
      ampm:ampm
    })
  }
  const ProductMRP = async (data) => {
    console.log(data)
    let Total = data && data.reduce(function (prev, current) {
      const Price = parseFloat(current.product.price)
      return prev + +Price * current.quantity
    }, 0)
    let sum = data && data.reduce(function (prev, current) {
      const updatedItem = { ...current };
      const offerPrice = parseFloat(current.product.offer_price);
      const price = parseFloat(current.product.price);
      if (offerPrice === 0) {
        updatedItem.product.price = (price + offerPrice)
        console.log(updatedItem.product.price, updatedItem.quantity)
        return prev + +updatedItem.product.price * updatedItem.quantity
      } else if (offerPrice > 0) {
        updatedItem.product.price = offerPrice
        return prev + +updatedItem.product.price * updatedItem.quantity
      }
      return prev
    }, 0);
    let percent = Total - sum
    setOrderMRP({
      mrp: Total,
      discount: Total - sum,
      totalAmount: sum,
      percentage: percent / Total * 100
    })
  }
  const GetEditdata = async () => {
    try {
      const response = await axiosConfig.get(`/accounts/address/${address}/`)
      console.log(response)
      setAddress(response.data)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetOrders()
    GetEditdata()
    GetData();
    GetlocaData()
    ProductMRP(Orders)
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, [])
  return (
    <>
      <Navbar />
      {
        isMobile?
        <div className="container-fluid my-profile_mobile">
        <div className="row">
          <div className="col-12 col-sm-12 order-overflow">
            <NavLink to="/account/orders" className="back-to-orders"><AiOutlineArrowLeft /></NavLink>
            <div className="order-content">
              <h5 className="">Yay! Order successfully delivered</h5>
              <p className="">Placed on {OrderDate.dayOfWeek}, {OrderDate.dayOfMonth} {OrderDate.month} {OrderDate.year},{OrderDate.hours + ":" + OrderDate.minutes}{OrderDate.ampm}</p>
            </div>

            <div className="pay-mode">
              <div className="p-3">
                <h5>Payment mode</h5>
                <p>Paid online</p>
              </div>
            </div>

            <div className="order-summary">
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="total-items">{Orders.length} items</h5>
                    <p>Order Id:{orderId}</p>
                  </div>
                  <a href="#" className="download-summary">Download Summary</a>
                </div>
                {
                  Orders && Orders.map((order, i) => {
                    return (
                      <div key={i} >
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-5">
                            <img src={order.product.image} alt="" className="order-item-image" />
                            <div>
                              <p>{order.product.name}</p>
                              <p>{order.product.weight_in_kg}</p>
                              <p>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) : "₹" + parseFloat(order.product.price)}*{order.quantity}</p>
                            </div>
                          </div>
                          <span>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) : "₹" + parseFloat(order.product.price)}</span>
                        </div>
                        <hr />
                      </div>
                    )
                  })
                }
              </div>

            </div>

            <div className="order-bill-details">
              <div className="p-3">
                <h5>Bill Details</h5>
                <div className="d-flex justify-content-between">
                  <p>MRP</p>
                  <p>₹{OrderMRP.mrp}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>discount</p>
                  <p>₹{OrderMRP.discount}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h6>final paid amount</h6>
                  <p>₹{OrderMRP.totalAmount}</p>
                </div>
                <div>
                  <div className="d-flex justify-content-between px-3 mb-5 order-saving">
                    <p className="mb-0">your savings</p>
                    <p className="mb-0">{OrderMRP.percentage.toFixed(0)}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="pay-mode">
              <div className="p-3">
                <h5>Delivery Address</h5>
                <p>Address:{Address.address},{Address.flat_no},{Address.landmark},{Address.pincode}  ,Telangana,India</p>
              </div>
            </div>

            <div className="mx-4 mt-2">
              <NavLink to="#">
                <button className="btn btn-block customer-support">
                  Customer support
                </button>
              </NavLink>
            </div>

          </div>
        </div>
      </div>:
      <div className="container my-profile_rn">
      <div className="row">
        <div className="col-lg-2 col-md-2 profile-main">
          <div className="profile-nav__details-block">
            <div class="account-profile__img"></div>
            <div class="account-profile__phone">+91{localStorage.getItem("number")}</div>
          </div>
          <nav className="profile-nav__list">
            <ul className="list-unstyled">
              <NavLink to="/account/addresses" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/addresses")}><li className="item-text"><span className="mx-2"><MdOutlineEditLocation /></span>My Addresses</li></NavLink>
              <NavLink to="/account/orders" className={ActiveUrl === "/account/orders" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/orders")}><li className="item-text"><span className="mx-2"><AiOutlineReconciliation /></span>My Orders</li></NavLink>
              <NavLink to="/account/wallet" className={ActiveUrl === "/account/wallet" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/account/wallet")}><li className="item-text"><span className="mx-2"><BiRupee /></span>My wallet</li></NavLink>
              <NavLink to="/logout" className={ActiveUrl === "/account/addresses" ? "profile-nav__list-item-active" : "profile-nav__list-item"} onClick={() => ActiveData("/logout")}><li className="item-text"><span className="mx-2"><AiOutlineUser /></span>Logout</li></NavLink>
            </ul>
          </nav>
        </div>
        <div className="col-lg-10 col-md-10 order-overflow">
          <NavLink to="/account/orders" className="back-to-orders"><AiOutlineArrowLeft /></NavLink>
          <div className="order-content">
            <h5 className="">Yay! Order successfully delivered</h5>
            <p className="">Placed on {OrderDate.dayOfWeek}, {OrderDate.dayOfMonth} {OrderDate.month} {OrderDate.year},{OrderDate.hours + ":" + OrderDate.minutes}{OrderDate.ampm}</p>
          </div>

          <div className="pay-mode">
            <div className="p-3">
              <h5>Payment mode</h5>
              <p>Paid online</p>
            </div>
          </div>

          <div className="order-summary">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="total-items">{Orders.length} items</h5>
                  <p>Order Id:{orderId}</p>
                </div>
                <a href="#" className="download-summary">Download Summary</a>
              </div>
              {
                Orders && Orders.map((order, i) => {
                  return (
                    <div key={i} >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-5">
                          <img src={order.product.image} alt="" className="order-item-image" />
                          <div>
                            <p>{order.product.name}</p>
                            <p>{order.product.weight_in_kg}</p>
                            <p>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) : "₹" + parseFloat(order.product.price)}*{order.quantity}</p>
                          </div>
                        </div>
                        <span>{parseFloat(order.product.offer_price) > 0 ? "₹" + parseFloat(order.product.offer_price) : "₹" + parseFloat(order.product.price)}</span>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
            </div>

          </div>

          <div className="order-bill-details">
            <div className="p-3">
              <h5>Bill Details</h5>
              <div className="d-flex justify-content-between">
                <p>MRP</p>
                <p>₹{OrderMRP.mrp}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>discount</p>
                <p>₹{OrderMRP.discount}</p>
              </div>
              <div className="d-flex justify-content-between">
                <h6>final paid amount</h6>
                <p>₹{OrderMRP.totalAmount}</p>
              </div>
              <div>
                <div className="d-flex justify-content-between px-3 mb-5 order-saving">
                  <p className="mb-0">your savings</p>
                  <p className="mb-0">{OrderMRP.percentage.toFixed(0)}%</p>
                </div>
              </div>
            </div>
          </div>

          <div className="pay-mode">
            <div className="p-3">
              <h5>Delivery Address</h5>
              <p>Address:{Address.address},{Address.flat_no},{Address.landmark},{Address.pincode}  ,Telangana,India</p>
            </div>
          </div>

          <div className="mx-4 mt-2">
            <NavLink to="#">
              <button className="btn btn-block customer-support">
                Customer support
              </button>
            </NavLink>
          </div>

        </div>
      </div>
    </div>
      }
    </>
  )
}
export { MyOrderDetails }